import { Card,Box, Typography, Divider,Grid,Avatar } from '@mui/material'
import { Container } from '@mui/system'
import React, { Component } from 'react'
import maam from "../maam.png"

import mone from "../facultyimg/mone.png"
import mtwo from "../facultyimg/mtwo.png"
import mthree from "../facultyimg/mthree.png"
import mfour from "../facultyimg/mfour.png"
import mfive from "../facultyimg/mfive.png"
import msix from "../facultyimg/msix.png"
import mseven from "../facultyimg/mseven.png"

export class Faculty extends Component {
  render() {
    return (
      <div>
   <Container maxWidth="lg">
   <br/>
   <Card sx={{minHeight:500,backgroundColor:'white'}}>
   <Box sx={{display:'flex',justifyContent:'center'}}>
   <Typography sx={{fontSize:20,margin:2,fontWeight:'550'}}>Faculty Team</Typography>
   </Box>
   <Divider/>
 <br/>
<Box style={{marginLeft:'10%',marginRight:'10%',display:'flex',justifyContent:'center'}}>

<Grid container spacing={3}>
  <Grid item xs={12} sm={12} md={3} lg={3}>
  <Box sx={{border:1,borderColor:'#d3d3d3',height:230,width:'100%'}}>

  <Box sx={{display:'flex',justifyContent:'center'}}>
  <img
  style={{height:170,width:170}}
  src={mone}
  />

</Box>

<Typography align='center' sx={{fontFamily:'serif'}}>MS Akash</Typography>
  <Typography align='center' sx={{fontFamily:'serif',fontSize:12}}>Chemistry (12 years)</Typography>
  


  
  </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={3} lg={3} >
  <Box sx={{border:1,borderColor:'#d3d3d3',height:230,width:'100%'}}>

  <Box sx={{display:'flex',justifyContent:'center'}}>
  <img
  style={{height:170,width:170}}
  src={mtwo}
  />

</Box>

<Typography align='center' sx={{fontFamily:'serif'}}>Prof Shrivastav</Typography>
  <Typography align='center' sx={{fontFamily:'serif',fontSize:12}}>MSc Mathematics (18 years)</Typography>
  



  
  </Box>





  </Grid>
  <Grid item xs={12} sm={12} md={3} lg={3} >
  <Box sx={{border:1,borderColor:'#d3d3d3',height:230,width:'100%'}}>

  <Box sx={{display:'flex',justifyContent:'center'}}>
  <img
  style={{height:170,width:170}}
  src={mthree}
  />

</Box>

<Typography align='center' sx={{fontFamily:'serif'}}>Prof. Kulkarni</Typography>
  <Typography align='center' sx={{fontFamily:'serif',fontSize:12}}>Physics (16 years)</Typography>
  



  
  </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={3} lg={3}>
  <Box sx={{border:1,borderColor:'#d3d3d3',height:230,width:'100%'}}>

  <Box sx={{display:'flex',justifyContent:'center'}}>
  <img
  style={{height:170,width:170}}
  src={mfour}
  />

</Box>

<Typography align='center' sx={{fontFamily:'serif'}}>Dr. Deepa Seth</Typography>
  <Typography align='center' sx={{fontFamily:'serif',fontSize:12}}>BHMS</Typography>
  



  
  </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={3} lg={3}>
  <Box sx={{border:1,borderColor:'#d3d3d3',height:230,width:'100%'}}>

  <Box sx={{display:'flex',justifyContent:'center'}}>
  <img
  style={{height:170,width:170}}
  src={mfive}
  />

</Box>

<Typography align='center' sx={{fontFamily:'serif'}}>Prof. Pradeep</Typography>
  <Typography align='center' sx={{fontFamily:'serif',fontSize:12}}>MSc Chemistry (Gold Medalist)
  </Typography>
  



  
  </Box>
  </Grid>

  <Grid item xs={12} sm={12} md={3} lg={3}>
  <Box sx={{border:1,borderColor:'#d3d3d3',height:230,width:'100%'}}>

  <Box sx={{display:'flex',justifyContent:'center'}}>
  <img
  style={{height:170,width:170}}
  src={msix}
  />

</Box>

<Typography align='center' sx={{fontFamily:'serif'}}>Prof Mahadev</Typography>
  <Typography align='center' sx={{fontFamily:'serif',fontSize:12}}>BTech IIT Bombay (12 years)
 
  </Typography>
  



  
  </Box>
</Grid>
<Grid item xs={12} sm={12} md={3} lg={3}>

<Box sx={{border:1,borderColor:'#d3d3d3',height:230,width:'100%'}}>

  <Box sx={{display:'flex',justifyContent:'center'}}>
  <img
  style={{height:170,width:170}}
  src={mseven}
  />

</Box>

<Typography align='center' sx={{fontFamily:'serif'}}>Prof. Ashok Dhuldhule</Typography>
  <Typography align='center' sx={{fontFamily:'serif',fontSize:12}}>Msc(Botany)</Typography>
  </Box>

</Grid>
</Grid>
</Box>
<br/>
<br/>
   </Card>
   <br/>
   </Container>
      </div>
    )
  }
}

export default Faculty