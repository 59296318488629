import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
// 
import two from "../assets/two.jpg"
export class Comperhensivetwentythree  extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
      <Box sx={{}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:30,md:30},fontFamily:'serif'}}>Course Details</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#00337b'}}>
    
<Box sx={{marginLeft:{xs:1,sm:1,md:15,lg:15},marginRight:{xs:1,sm:1,md:15,lg:15}}}>
<Typography  sx={{fontWeight:700,fontFamily:'serif',fontSize:24,lineHeight:3,color:'white'}}>Comprehensive 2023(JEE)</Typography>
<Divider sx={{backgroundColor:'white'}}/>
<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>Are you ready to unlock your full potential and achieve success in the highly competitive engineering entrance exams? Our two-year course is specifically designed to give you the edge you need to excel.
</Typography>

<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>We understand that to succeed in these exams, you need more than just a strong understanding of Physics, Chemistry, and Mathematics. You also need the ability to solve new and unseen questions with accuracy and speed. That's why our course is designed to focus on:
</Typography>
<br/>



<Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
<Box sx={{display:'flex',alignItems:'center'}}>
<CircleIcon sx={{height:8,width:8,color:"white",display:'flex'}}/>
</Box>
 <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'serif',color:'white'}}> Strengthening your core concepts and fundamentals, </Typography>
 </Box>

 <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,}}>
 <Box sx={{display:'flex'}}>
 <CircleIcon sx={{height:8,width:8,color:"white",display:'flex',mt:1}}/>
 </Box>
  <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'serif',color:'white'}}>Sharpening your analytical skills and developing your problem-solving abilities</Typography>
  </Box>

  <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
  <Box sx={{display:'flex'}}>
  <CircleIcon sx={{height:8,width:8,color:"white",display:'flex',mt:1}}/>
  </Box>
   <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'serif',color:'white'}}> Building speed and accuracy through intensive problem-solving sessions and tests.
   </Typography>
   </Box>
  
   <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
   <Box sx={{display:'flex',}}>
   <CircleIcon sx={{height:8,width:8,color:"white",display:'flex',mt:1}}/>
   </Box>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'serif',color:'white'}}> With this comprehensive approach, our course design ensures that a sincere student is bound to acquire a grip on exams and cultivate self-confidence, and develop an easy 'feel' for all engineering entrance exams.
    </Typography>
    </Box>

   <br/>

<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>
This two-year course is tailored for students currently in 10th grade and with over 1800 hours of classroom teaching, you'll be well-prepared for all major engineering entrance exams.
</Typography>
  <br/>
  <Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>
  Don't let anything stand in the way of your engineering dreams. Enroll in our course today and gain the knowledge, skills, and confidence you need to excel in the engineering entrance exams and secure your place in the top engineering colleges.
</Typography>
 


<br/>

<Typography sx={{fontSize:16,fontFamily:'serif',color:'white'}}>For enquiry, contact us on 98508 44937</Typography>
<br/>

<Divider/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>







</Box>

    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      </div>
    )
  }
}

export default Comperhensivetwentythree




