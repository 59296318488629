import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
import two from '../assets/two.jpg'


export class Helpfindcouses extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>

      <Box sx={{}}>
      <br/>
      <Typography align='center' sx={{fontSize:40,fontFamily:'sans-serif'}}>Event Details</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#00337b'}}>
      <Grid container columnSpacing={1}>
      <Grid item xs={12} sm={12} md={9} lg={9}>
       <Box sx={{minHeight:600,backgroundColor:'#f5f3f3',margin:2}}>
       <Typography sx={{fontSize:21,fontFamily:'sans-serif',color:'#676767 '}}>Find the correct course for you.</Typography>
       <br/>
       <Typography sx={{fontSize:35,color:'black',fontFamily:'sans-serif',fontWeight:'bold'}}>Available Course Details :</Typography>
       <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#676767'}}>  Entrance Exam for 7th, 8th passed students (Foundation Basic Course) - 26th January 2023</Typography>
<br/>
       <Box sx={{display:'flex',flexDirection:'row',marginLeft:2,justifyContent:'center'}}>
       <CircleIcon sx={{height:10,width:10,color:"black",display:'flex',alignItems:'center'}}/>
       <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#676767'}}>  Entrance Exam for 7th, 8th passed students (Foundation Basic Course) - 26th January 2023</Typography>
       </Box>
    <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>

    <Box sx={{display:'flex',flexDirection:'row',marginLeft:2,mt:1,justifyContent:'center'}}>
    <CircleIcon sx={{height:10,width:10,color:"black",display:'flex',alignItems:'center'}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#676767'}}>  Entrance Exam for 7th, 8th passed students (Foundation Basic Course) - 26th January 2023</Typography>
    </Box>
 <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>

 <Box sx={{display:'flex',flexDirection:'row',marginLeft:2,mt:1,justifyContent:'center'}}>
 <CircleIcon sx={{height:10,width:10,color:"black",display:'flex',alignItems:'center'}}/>
 <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#676767'}}>  Entrance Exam for 7th, 8th passed students (Foundation Basic Course) - 26th January 2023</Typography>
 </Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>

<Box sx={{display:'flex',flexDirection:'row',marginLeft:2,mt:1,justifyContent:'center'}}>
       <CircleIcon sx={{height:10,width:10,color:"black",display:'flex',alignItems:'center'}}/>
       <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#676767'}}>  Entrance Exam for 7th, 8th passed students (Foundation Basic Course) - 26th January 2023</Typography>
       </Box>
    <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>

       </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>   
      <Box sx={{minHeight:600,margin:2,}} >
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif'}} >RECENT EVENTS</Typography>
<Divider sx={{backgroundColor:'blue'}}/>

      <Typography
      sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
          color:'#676767',
          marginRight:2,
          fontWeight: 600,
          marginLeft:1,
          fontSize:{xs:12,sm:12,md:14,lg:14},
          width:'100%',fontFamily:'sans-serif'
          ,'&:hover': {borderBottom:'1px solid blue',color:'blue',}}}>
      
          Entrance Exam for students m
  </Typography>
  <Typography  sx={{fontSize:{xs:9,sm:9,md:11,lg:11,},marginLeft:1, color:'#676767',fontFamily:'sans-serif'}} >2016-07-33</Typography>
<Divider/>


<Typography
sx={{
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    color:'#676767',
    marginRight:2,
    marginLeft:1,
    fontWeight: 600,
    fontSize:{xs:12,sm:12,md:14,lg:14},
    width:'100%',fontFamily:'sans-serif'
    ,'&:hover': {borderBottom:'1px solid blue',color:'blue',}}}>

hi we have a good knowlege in cokan and soluble section amd paid 
</Typography>
<Typography  sx={{fontSize:{xs:9,sm:9,md:11,lg:11,},marginLeft:1, color:'#676767',fontFamily:'sans-serif'}} >2016-07-33</Typography>
<Divider/>


<Typography
sx={{
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    color:'#676767',
    marginRight:2,
    marginLeft:1,
    fontWeight: 600,
    fontSize:{xs:12,sm:12,md:14,lg:14},
    width:'100%',fontFamily:'sans-serif'
    ,'&:hover': {borderBottom:'1px solid blue',color:'blue',}}}>

hi we have a good knowlege in cokan and soluble section amd paid 
</Typography>
<Typography  sx={{fontSize:{xs:9,sm:9,md:11,lg:11,},marginLeft:1, color:'#676767',fontFamily:'sans-serif'}} >2016-07-33</Typography>
<Divider/>






      </Box>
      </Grid>
    </Grid>
    </Box>
      </Container>
      </Box>


      </Box>
      </div>
    )
  }
}

export default Helpfindcouses