import { Container } from '@mui/system'
import React, { Component } from 'react'
import { Box, Typography,Grid ,Button,TextField,Divider} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle';
import { withRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
export class Blogreading  extends Component {
    
componentDidMount(){
 
}



mm=()=>{
    alert("jwkejkw")
}

  render() {
    
    return (
      <div>
      <Typography>{this.props.location.state.id}</Typography>
      <Container maxWidth="lg">
      <Box sx={{backgroundColor:"#00337b",height:130,}}>
      <Box>
      <br/>
      <Typography align='left' sx={{color:"white",fontSize:25,marginLeft:5,fontFamily:'sans-serif'}}>Insights by SAHU ACADEMY Tutorials</Typography>
      <Typography align='left' sx={{color:"white",fontSize:17,marginLeft:5,fontFamily:'sans-serif'}}>Official sahu academy blog about optimal study practices for competitive exams like JEE/ Olympiads / KVPY /MHT CET</Typography>
      </Box>
      </Box>


      <Box sx={{marginLeft:{xs:1,sm:1,md:5,lg:5},marginRight:{xs:1,sm:1,md:5,lg:5}}}>
      <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={8} lg={8} >
      <Box sx={{minHeight:100}}>

      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
       <br/>
      <Typography  align='left' sx={{fontSize:{xs:25,sm:25,md:30,lg:30},color:"#00337b",fontFamily:'serif'}}>Increase in MHT-CET difficulty level: Blessing in disguise!</Typography>
<br/>
      <Typography  align='justify' sx={{fontSize:{xs:17,sm:17,md:19,lg:19},color:"#00337b",fontFamily:'serif'}}>We appreciate your sincerity and determination to clear the important examination ahead of you. Your decision to consider Bakliwal Tutorials to be your trainer bears ample testimony to your resolve.
      Every smart individual realizes the importance of expert help at the right juncture. Behind every Edmund Hillary triumphantly waving a flag at the pinnacle is month of toil under the watchful eyes of a committed trainer. For the past 14 years, we at BT have been that trainer for many a successful students.</Typography>
      <br/>
      <Typography  align='justify' sx={{fontSize:{xs:17,sm:17,md:19,lg:19},color:"#00337b",fontFamily:'serif'}}>We appreciate your sincerity and determination to clear the important examination ahead of you. Your decision to consider Bakliwal Tutorials to be your trainer bears ample testimony to your resolve.
      Every smart individual realizes the importance of expert help at the right juncture. Behind every Edmund Hillary triumphantly waving a flag at the pinnacle is month of toil under the watchful eyes of a committed trainer. For the past 14 years, we at BT have been that trainer for many a successful students.</Typography>






      </Grid>
      
    </Grid>
      </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
      <br/>
      <Box sx={{backgroundColor:'#ededef',minHeight:50,marginLeft:2 ,display:'flex',justifyContent:'center'}}>
      <Box sx={{m:2}}>
      <TextField id="outlined-search" label="Search here....." size='small'  type="search" />
      <br/>
      </Box>
      </Box>
<br/>
      <Box sx={{backgroundColor:'#ededef',height:450,marginLeft:2 }}>
      <Box sx={{display:'flex',flexDirection:'row',}}>
      <Typography sx={{color:'red',fontFamily:'sans-serif',mt:2,ml:2,fontWeight:'bold'}}>Blogs Heading</Typography>
         </Box>


      <Box sx={{maxHeight:430,overflow:'scroll',mb:2,overflowX:'hidden',overflowY:'hidden' ,'&:hover': {overflowY:'scroll'}}}>
   <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'center'}}>
<Box sx={{display:'flex',alignItems:'center'}}>
 <CircleIcon sx={{height:8,width:8,color:"black"}}/>
</Box>
   <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:10,sm:10,md:12,lg:12,},width:'100%',fontFamily:'sans-serif','&:hover': {borderBottom:'1px solid blue',color:'blue'}}}>  Entrance Exam for 7th, 8th and founded in coctai  resiona  ds </Typography>
   </Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
</Box>
      </Box>


<br/>


<Box sx={{backgroundColor:'#ededef',height:100,marginLeft:2 }}>
<Typography  align='center'>Comments</Typography>



</Box>











      </Grid>
      </Grid>
      </Box>

      </Container>
      <br/>
      <br/>
      </div>
    )
  }
}

export default Blogreading