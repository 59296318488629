import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link ,TextField, Button, ButtonGroup} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import croppedlogo from "../src/Lg.png"


export class Feepayment extends Component {

    payy=()=>{
        var rzp1 = new window.Razorpay({
            key: 'rzp_test_DIwtLXaqbgtWMn',
            amount: '100', 
            name: 'thecoding.in',
            description: 'we like freelancing world',
            handler: function(response) {
                alert(response.razorpay_payment_id);
    
            }


        });
        rzp1.open();





    }
  render() {
    return (
      <div>
      <Box sx={{backgroundColor:"#e9efef"}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:30,sm:30,lg:35,md:35},fontFamily:'sans-serif'}}>Pay Fee online</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#fff',display:'flex',justifyContent:'center'}}>
      <Grid container >
      <Grid item xs={12} sm={12} md={6} lg={6}>
      <Box sx={{backgroundColor:'#00337b',minHeight:{xs:200,sm:200,md:600,lg:600}}}>
<br/>
      <Box sx={{display:'flex',justifyContent:'center'}} >
  <img src={croppedlogo} style={{height:120,objectFit:'contain',}}/>
  </Box>
         
<Box sx={{marginLeft:7,marginRight:7,display:{xs:'none',sm:'none',md:'block',lg:'block'}}}>
<Typography align='center' sx={{fontSize:30,color:'white',fontWeight:600}}>Top 15 student  will get </Typography>
<Box sx={{backgroundColor:'#ffcc01'}}>
<Typography align='center'sx={{m:1,fontSize:30,color:'blue',fontWeight:600}}>100% scholarships</Typography>
</Box>
<Typography align='center' sx={{fontSize:30,color:'white',fontWeight:600}}>on Coachining</Typography>
<br/>

<Typography align='center' sx={{fontSize:30,color:'white',fontWeight:600}}>FOR FREE COUNSELLING</Typography>
<br/>
<Box sx={{display:'flex',justifyContent:'center'}}>
<Button variant="contained" sx={{backgroundColor:'#ffcc01',color:'blue'}}>
Enquire now
</Button>
</Box>


</Box>

      </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box sx={{backgroundColor:'white',height:600,display:'flex',flexDirection:'column',marginLeft:{xs:1,sm:1,md:5,lg:5},marginRight:{xs:1,sm:1,md:5,lg:5}}}>
        <br/>
        <Typography align='center' sx={{m:2}}>Pay Fee </Typography>

        <TextField id="outlined-basic" label="User name" variant="outlined"  size='small' sx={{m:1,fontSize:10}}/>
        <TextField id="outlined-basic" label="Enter email id" variant="outlined"  size='small' sx={{m:1}}/>
        <TextField id="outlined-basic" label="Enter amount" variant="outlined"  size='small' sx={{m:1,fontSize:10}}/>
        <TextField id="outlined-basic" label="Enter mobile no " variant="outlined"  size='small' sx={{m:1}}/>
        <TextField id="outlined-basic" label="Enter course name (optional) " variant="outlined"  size='small' sx={{m:1}}/>



        <Autocomplete
      disablePortal
      id="combo-box-demo"
      size='small'
      options={date}
      sx={{m:1}}
      renderInput={(params) => <TextField {...params} label="select for pay fees" />}
    />
<br/>
        <Button onClick={this.pay} variant="contained" sx={{width:200}}>Pay fees</Button>


        
        </Box>
      </Grid>
    </Grid>






    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </div>
    )
  }
}

export default Feepayment


const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },]

    const date = [
        { label: 'For Entrance Exam', year: 1994 },
        { label: 'For New Admission', year: 1994 }
    ]
       