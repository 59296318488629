import { Card,Box, Typography, TextField ,Button} from '@mui/material';
import { Container } from '@mui/system';
import firebase from "../firebase"
import React, { Component } from 'react';
import two from "../assets/two.jpg"
class Carrior extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       name:"",
       mobileno:"",
       exprience:"",
       email:""
    }
    this.handlechange=this.handlechange.bind()
  }

handlechange=(e)=>{
  this.setState({
[e.target.name]:e.target.value

  })
}

submit=()=>{
  if(this.state.email==="" && this.state.name==="" && this.state.exprience===""&& this.state.mobileno==="")
  {
alert("All fiels are complesary ,Please fill all field")
  }else{
   const key =  firebase.database().ref("career").push().key
    firebase.database().ref("career").child(key).set({
      name: this.state.name,
      email: this.state.email,
      exprince:this.state.exprience,
      mobileno:this.state.mobileno,
      key:key,
    }).then(()=>{
      alert("Data Succesfully placed")
      this.setState({email:"",name:"" ,exprience:"", mobileno:""})
    })
    
  }
}

  render() {
    return (
      <div>
      <Container maxWidth='lg'>
      <Box sx={{backgroundImage:`url(${two})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
      <Card sx={{minHeight:400,mt:2,mb:2}}>
      <Box sx={{backgroundColor:'#00337b'}}>
      <Typography align='center' sx={{fontSize:19,fontFamily:'sans-serif',m:2,color:'white',}}>Career</Typography>
      </Box>


<Box sx={{marginLeft:{xs:"1%",sm:"1%",md:"20%",lg:"20%"},marginRight:{xs:"1%",sm:"1%",md:"20%",lg:"20%"}}}>
      <TextField
      required
      size='small'
      placeholder='Enter your name'
      id="outlined-required"
      onChange={this.handlechange}
      fullWidth
     name="name"
    />

<br/>
<br/>
    <TextField
    size='small'
    fullWidth
    placeholder='Your Email id'
    required
    id="outlined-required"
    onChange={this.handlechange}
    name="email"
  />
  <br/>
  <br/>
  <TextField
  size='small'
  required
  fullWidth
  id="outlined-required"
  onChange={this.handlechange}
  placeholder='Experience'
name="exprience"
/>
<br/>
<br/>
<TextField
required
fullWidth
placeholder='Enter your Mobile no'
size='small'
onChange={this.handlechange}
id="outlined-required"
name="mobileno"
/>
<br/>
<br/>



<Box sx={{display:'flex',justifyContent:"center"}}>
<Button variant="contained" onClick={this.submit}>Submit</Button>

</Box>

</Box>

      </Card>
      </Box>
      <br/>
      <br/>
      </Container>
      </div>

    );
  }
}

export default Carrior;
