import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
// Comperhensivetwentyfour.js
import two from "../assets/two.jpg"
export class Summerfoundation extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
      <Box sx={{}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:30,md:30},fontFamily:'serif'}}>Course Details</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#00337b'}}>
    
<Box sx={{marginLeft:{xs:1,sm:1,md:15,lg:15},marginRight:{xs:1,sm:1,md:15,lg:15}}}>
<Typography sx={{fontWeight:700,fontFamily:'serif',fontSize:24,lineHeight:3,color:'white'}}>Summer Foundation</Typography>
<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>Are you ready to jumpstart your journey towards success in competitive exams? Look no further than our Summer Foundation course!

</Typography>

<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>This 21-day course is specifically designed for students who have not completed any Foundation course and are planning to join the 2-year Comprehensive Course. It's the perfect opportunity to get a head start on your preparation and make the most of your vacation time.
</Typography>
<br/>

<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>
Our expertly crafted lectures will help you gain a strong foundation in Physics, Chemistry, and Mathematics, setting you up for success in the JEE and other competitive exams.

</Typography>
  <br/>
  <Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>
  This is your chance to gain an edge over your peers and start building the knowledge and skills you need to excel in competitive exams. Imagine feeling charged up and confident, ready to take on the challenges ahead.
</Typography>
  <br/>
  <Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>
  Don't miss out on this opportunity to jumpstart your journey towards success in competitive exams. Enroll in our Summer Foundation course today and start your path to success.
</Typography>
  <br/>

<br/>

<Typography sx={{fontSize:16,fontFamily:'serif',color:'white'}}>For enquiry, contact us on 98508 44937</Typography>
<br/>

<Divider/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>







</Box>

    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      </div>
    )
  }
}

export default Summerfoundation


