import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link ,TextField, Button, ButtonGroup, CircularProgress} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import croppedlogo from "../src/Lg.png"
import firebase from "../src/firebase"
import Circle from '@mui/icons-material/Circle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



export class Contact extends Component {
constructor(props) {
  super(props)

  this.state = {
    name:"",
type:"",
email:"",
contactno:"",
circle:false
  }
  this.handlechange=this.handlechange.bind();
}

handlechange=(e)=>{
  this.setState({[e.target.name]:e.target.value})
}

  render() {
    return (
      <div>
      <Box sx={{backgroundColor:"#e9efef"}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:25,md:25},fontFamily:'serif'}}>Contact Form</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#fff',display:'flex',justifyContent:'center'}}>
      <Grid container >
      <Grid item xs={12} sm={12} md={6} lg={6}>
      <Box sx={{backgroundColor:'#00337b',minHeight:{xs:200,sm:200,md:400,lg:400}}}>
<br/>
      <Box sx={{display:'flex',justifyContent:'left'}} >
  <img src={croppedlogo} style={{height:100,objectFit:'contain',}}/>
  </Box>
         
<Box sx={{marginLeft:6,marginRight:5}}>
<Typography sx={{fontSize:15,color:'whitesmoke',fontFamily:'sans-serif'}}>Contact number:</Typography>
<Typography sx={{fontSize:15,color:'whitesmoke',fontFamily:'sans-serif'}}>New Admission and Enquires :- 98508 44937</Typography>

<br/>
<Typography sx={{fontSize:15,color:'whitesmoke',fontFamily:'sans-serif'}}>CONTACT E-MAIL:</Typography>
<Typography sx={{fontSize:15,color:'whitesmoke',fontFamily:'sans-serif'}}>manish.g1986@gmail.com</Typography>

<br/>


</Box>

      </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box sx={{backgroundColor:'white',height:400,display:'flex',flexDirection:'column',marginLeft:{xs:1,sm:1,md:5,lg:5},marginRight:{xs:1,sm:1,md:5,lg:5}}}>
        <br/>
        <Typography align='center' sx={{m:2}}>Contact Form </Typography>



        

        <TextField onChange={this.handlechange} id="outlined-basic" label="For what purpose you are filling the form" name='type' variant="outlined"  size='small' sx={{m:1,fontSize:10}}/>



 <TextField onChange={this.handlechange} id="outlined-basic" label="Enter name" name='name' variant="outlined"  size='small' sx={{m:1,fontSize:10}}/>
        <TextField onChange={this.handlechange} id="outlined-basic" label="Enter email id" name='email' variant="outlined"  size='small' sx={{m:1}}/>
        
    

        <TextField onChange={this.handlechange} id="outlined-basic" label="Enter contact no " name='contactno' variant="outlined"  size='small' sx={{m:1}}/>
<br/>

{
  this.state.circle?<Box sx={{display:'flex',justifyContent:'center'}}><CircularProgress size={24} /> </Box>:null
}

<br/>
        <Button variant="contained" sx={{width:200}} onClick={()=>{

if(this.state.contactno!==""||this.state.email!==""||this.state.name!==""||this.state.type)

{



console.log(this.state.type,this.state.contactno,this.state.email,this.state.name)
this.setState({circle:true})

          const key= firebase.database().ref("contactform").push().key
          firebase.database().ref("contactform").child(key).set({
        name:this.state.name,
        contactno:this.state.contactno,
        email:this.state.email,
        type:this.state.type,
        key:key,
          }).then((d)=>{
           
          //  this.setState({paragraph:[],paragraphhead:""})
              alert("data successfully placed");
              this.setState({circle:false})
              window.location.reload()
          })
      

 }else{
alert("All fields are complessary")



}




        }}>Submit</Button>


        
        </Box>
      </Grid>
    </Grid>






    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </div>
    )
  }
}

export default Contact


const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },]

    const date = [
        { label: 'suggestion', year: 1994 },
        { label: 'Query', year: 1994 },
        { label: 'Other', year: 1994 },
       
    
    ]
       