import { Container } from '@mui/system'
import React, { Component } from 'react'
import { Box, Typography,Grid ,Button,TextField,Divider, touchRippleClasses} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate,useLocation } from 'react-router-dom';
import firebase from "./firebase"
export class Blog extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         data:[]
      }
    }


componentDidMount(){
  var ref = firebase.database().ref().child("Blogs");
        let data = [];
        ref.once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
             
              var childData = childSnapshot.val();
              
              data.push(childData);

            this.setState({data:data})
             
              // ...
            });
          });
          
  
}




mm=()=>{
    alert("jwkejkw")
}
next=(post)=>{
  console.log(post)
  const poo = post;
 this.props.navigate('/funtest',{state:{po:poo}})
}
  render() {
    return (
      <div>
      <Container maxWidth="lg">
      <Box sx={{backgroundColor:"#00337b",minHeight:130,}}>
      <Box>
      <br/>
      <Typography align='left' sx={{color:"white",fontSize:25,marginLeft:5,fontFamily:'sans-serif'}}>Insights by SAHU ACADEMY Tutorials</Typography>
      <Typography align='left' sx={{color:"white",fontSize:17,marginLeft:5,fontFamily:'sans-serif'}}>Official sahu academy blog about optimal study practices for competitive exams like JEE/ Olympiads / KVPY /MHT CET</Typography>
      </Box>
      </Box>


      <Box sx={{marginLeft:{xs:1,sm:1,md:5,lg:5},marginRight:{xs:1,sm:1,md:5,lg:5}}}>
      <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={8} lg={8} >
      <Box sx={{minHeight:100}}>

      <Grid container spacing={2}>





      {
         this.state.data.map((post ,index) =>
         <Grid item xs={12} sm={6} md={6} lg={6}>
         <Box sx={{minHeight:100,backgroundColor:'white'}}>
         <br/>
       <Typography align='justify' sx={{fontSize:20,color:'#00337b',fontFamily:'serif', display: '-webkit-box',
       overflow: 'hidden',
       WebkitBoxOrient: 'vertical',
       WebkitLineClamp: 2,}}>{post.head}</Typography>

   { post.paragraph.map((option,index) => 
             
      index==0?
      <Typography align='justify' sx={{
         mt:4,
         fontSize:16,

         display: '-webkit-box',
         overflow: 'hidden',
         WebkitBoxOrient: 'vertical',
         WebkitLineClamp: 3,
     fontFamily:'serif'
     }}>
       {option.ss}
        </Typography>
      :null
  )}

  <Button variant="contained" onClick={()=>this.next(post)} sx={{backgroundColor:'#00337b',marginTop:3,textTransform:'none'}}>Reading</Button>

      </Box>
        </Grid>
     
         )
     }




    </Grid>
      </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
      <br/>
      <Box sx={{backgroundColor:'#ededef',minHeight:50,marginLeft:2 ,display:'flex',justifyContent:'center'}}>
      <Box sx={{m:2}}>
      <TextField id="outlined-search" label="Search here....." size='small'  type="search" />
      <br/>
      </Box>
      </Box>
<br/>
      <Box sx={{backgroundColor:'#ededef',height:450,marginLeft:2 }}>
      <Box sx={{display:'flex',flexDirection:'row',}}>
      <Typography sx={{color:'red',fontFamily:'sans-serif',mt:2,ml:2,fontWeight:'bold'}}>Blogs Heading</Typography>
         </Box>


      <Box sx={{maxHeight:410,overflow:'scroll',mb:2,overflowX:'hidden',overflowY:'hidden' ,'&:hover': {overflowY:'scroll'}}}>


      {
         this.state.data.map((post ,index) =>
         <Box>
         <Box sx={{display:'flex',flexDirection:'row',m:1,justifyContent:'center'}}>
         <Box sx={{display:'flex'}}>
          <CircleIcon sx={{height:8,width:8,color:"black",mt:0.5}}/>
         </Box>
            <Typography onClick={()=>this.next(post)}  align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:10,sm:10,md:12,lg:12,},width:'100%',fontFamily:'sans-serif','&:hover': {borderBottom:'1px solid blue',color:'blue'}}}>{post.head}</Typography>
            </Box>
         <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
         </Box>
         
         )
     }
     

</Box>
      </Box>


<br/>


<Box sx={{backgroundColor:'#ededef',height:100,marginLeft:2 }}>
<Typography  align='center'>Comments</Typography>



</Box>











      </Grid>
      </Grid>
      </Box>

      </Container>
      <br/>
      <br/>
      </div>
    )
  }
}




export function Blogc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Blog navigate={navigate}></Blog>)
}






export default Blog




const datas = [

   {
       id:1,
       heading:"Giving your 100% on the exam day- JEE Mains and Advanced",
       paragraph:[
           {
           head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
          },
          {
           head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
          },
          {
           head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
          },
          {
           head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
          },]


   },
   {
       id:1,
       heading:"Giving your 100% on the exam day- JEE Mains and Advanced",
       paragraph:[
         {
         head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
        },
        {
         head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
        },
        {
         head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
        },
        {
         head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
        },]
},
{
   id:2,
   heading:"Giving your 100% on the exam day- JEE Mains and Advanced",
   paragraph:[
       {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },]


},
{
   id:3,
   heading:"Giving your 100% on the exam day- JEE Mains and Advanced",
   paragraph:[
       {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },]


},
{
   id:5,
   heading:"Giving your 100% on the exam day- JEE Mains and Advanced",
   paragraph:[
       {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },]


},
{
   id:1,
   heading:"Giving your 100% on the exam day- JEE Mains and Advanced",
   paragraph:[
       {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },]


},
{
   id:1,
   heading:"Giving your 100% on the exam day- JEE Mains and Advanced",
   paragraph:[
       {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },]


}, {
   id:1,
   heading:"Giving your 100% on the exam day- JEE Mains and Advanced",
   paragraph:[
       {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },]


},
{
   id:1,
   heading:"Giving your 100% on the exam day- JEE Mains and Advanced",
   paragraph:[
       {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },
      {
       head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
      },]


},





   
   
   
   ]
   