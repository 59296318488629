import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
// 
import two from "../assets/two.jpg"
export class Prefoundationbasic  extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
      <Box >
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:30,md:30},fontFamily:'serif'}}>Course Details</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#00337b'}}>
   
<Box sx={{marginLeft:{xs:1,sm:1,md:15,lg:15},marginRight:{xs:1,sm:1,md:15,lg:15}}}>
<Typography sx={{fontWeight:700,fontFamily:'serif',fontSize:24,lineHeight:3,color:'white'}} >Pre Foundation Basic</Typography>
<Divider sx={{backgroundColor:'white'}}/>
<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>Are you ready to give your child a head start on their academic journey and set them up for success in competitive exams? Look no further than our Pre-Foundation course specifically tailored for 5th and 6th passed students.
</Typography>

<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>This novel concept is designed to work on students' analytical thinking and problem-solving abilities at an early stage. These skills are paramount for conquering quality national-level competitive exams like JEE, NEET, CAT, etc in the future.
</Typography>
<br/>

<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>
Developing such skills in the formative years will not only give them a competitive edge over their peers but also help them ignite a strong interest in preparation for competitive exams. With this objective in mind, our course will cover lectures on important concepts of Mathematics, logical thinking, and mental ability.
</Typography>
  <br/>
  <Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>
  Don't wait to give your child an advantage in their academic journey. Enroll them in our Pre-Foundation course today and set them on the path to success in competitive exams.
</Typography>
 


<br/>

<Typography sx={{fontSize:16,fontFamily:'serif',color:'white'}}>For enquiry, contact us on 98508 44937</Typography>
<br/>

<Divider/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>







</Box>

    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      </div>
    )
  }
}

export default Prefoundationbasic




