import React, { Component } from 'react'
import { Box } from '@mui/system'
import two from "../assets/twocr.jpg"
import { Container, Divider, Typography } from '@mui/material'
import Stepperforresultinotherexam from '../Stepperforresultinotherexam'
import CircleIcon from '@mui/icons-material/Circle';
export class Resultinotherexam extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundImage:`url(${two})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
      <br/>
      
      <Typography align='center' sx={{fontSize:{xs:30,sm:30,md:36,lg:36},fontFamily:'serif'}}>Results In Other Exam</Typography>
      <br/>
      <Container maxWidth='lg'>
      <Box sx={{backgroundColor:"#00337b",minHeight:300}}>
      <br/>
      

      <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
      <Box sx={{display:'flex',marginLeft:2}}>
       <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
      </Box>
      <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>For exams such as the MHT-CET, our success rate is a perfect 100%. Our approach to teaching ensures that our students are well-prepared and confident on exam day.



      </Typography>
      </Box>
      <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
   
      

      <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
      <Box sx={{display:'flex',marginLeft:2}}>
       <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
      </Box>
      <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>For Enquiry, contact us on 98508 44937

      </Typography>
      </Box>
      <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
   
    

      </Box>
      </Container>
      <br/>
      <br/>
      </Box>
      </div>
    )
  }
}

export default Resultinotherexam