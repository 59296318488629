import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
import two from "../assets/two.jpg"
export class Comperhensivetwentyfive extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
      <Box sx={{}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:30,md:30},fontFamily:'serif'}}>Course Details</Typography>
      
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#00337b'}}>
   
<Box sx={{marginLeft:{xs:1,sm:1,md:15,lg:15},marginRight:{xs:1,sm:1,md:15,lg:15}}}>
<Typography sx={{fontWeight:700,color:'white',fontFamily:'serif',fontSize:24,lineHeight:3}} >Comprehensive 2025 (JEE) </Typography>
<Divider sx={{backgroundColor:'white'}}/>
<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white',}}>Are you dreaming of a career in engineering? Achieving success in the highly competitive engineering entrance exams requires more than just a strong understanding of Physics, Chemistry, and Mathematics. It also demands the ability to solve new and unseen questions accurately and quickly.</Typography>
<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white',}}>Our two-year course is designed to give you the edge you need to succeed. We focus on strengthening your core concepts and fundamentals, sharpening your analytical skills, and developing your problem-solving abilities. Intensive problem-solving sessions and tests will help you build speed and accuracy so that you can take on any question with confidence.</Typography>
<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white',}}>This comprehensive course is tailored for students currently in 10th grade, with over 1800 hours of classroom teaching, you will be well prepared for all major engineering entrance exams including JEE Advanced, JEE Mains, BITSAT, and boards.
</Typography>
<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white',}}>Take the first step towards your engineering dreams by enrolling in our course today. With our expert guidance and support, you will gain the knowledge, skills, and confidence to excel in the engineering entrance exams and secure your place in the top engineering colleges.
</Typography>






  
   
    
    
<br/>

<Typography sx={{fontSize:16,fontFamily:'serif',color:'white',}}>For enquiry, contact: 98508 44937</Typography>
<br/>

<Divider/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>







</Box>

    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      </div>
    )
  }
}

export default Comperhensivetwentyfive






