import React, { Component } from 'react'
import { Card,Box, Typography, TextField ,Button,Paper, Link} from '@mui/material';
import { Container } from '@mui/system';

import NEETChemicalTransportQuestions from "../Download/NEETChemicalTransportQuestions.pdf"
import NEETChemicalTransportSolutions from "../Download/NEETChemicalTransportSolutions.pdf"
import shmquestion from "../Download/NEET - SHM Questions.pdf"
import shmsolution from "../Download/NEET - SHM Solutions.pdf"



import s from "../Download/NEET - Structure of Atom Questions.pdf"
import e from "../Download/NEET - Structure of Atom Solutions.pdf"
import p from "../Download/NEET - Surface Chemistry Questions.pdf"
import r from "../Download/NEET - Surface Chemistry Solutions.pdf"
import l from "../Download/NEET - Transport in Plants Questions.pdf"
import w from "../Download/NEET - Transport in Plants Solutions.pdf"










export class Downloads extends Component {
  render() {
    return (
      <div>
      <br/>
      <Container maxWidth="lg">
      <Paper elevation={1}>
      <Box sx={{minHeight:200}}>
      <Box sx={{backgroundColor:"#2f3f8c"}}>
      <br/>
      <Typography align='center' sx={{color:'white',fontSize:19}}>Important Links to be download</Typography>
      <br/>
      </Box>
      

      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,mr:1,fontWeight:600,mt:4}}>NEET - Chemical Transport Questions  -  <Link href={NEETChemicalTransportQuestions} download="NEET - Chemical Transport Questions.pdf">Download</Link></Typography>
      </Box>


      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1,fontWeight:600}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>NEET - Chemical Transport Solutions  -  <Link href={NEETChemicalTransportSolutions}>Download</Link></Typography>
      </Box>


      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1,fontWeight:600}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>NEET - SHM Questions -  <Link href={shmquestion}>Download</Link></Typography>
      </Box>


      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1,fontWeight:600}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}> NEET - SHM Solutions - <Link href={shmsolution}>Download</Link></Typography>
      </Box>




      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>NEET - Structure of Atom Questions -  <Link href={s}>Download</Link></Typography>
      </Box>

<Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
      <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>NEET - Structure of Atom Solutions -  <Link href={e}>Download</Link></Typography>
            </Box>
      

 <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
            <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>NEET - Surface Chemistry Questions -  <Link href={p}> Download </Link></Typography>
</Box>
           

<Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>NEET - Surface Chemistry Solutions -  <Link href={r}>Download</Link></Typography>
      </Box>




      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
      <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>NEET - Transport in Plants Questions -  <Link href={l}>Download</Link></Typography>
            </Box>


            <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
            <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>NEET - Transport in Plants Solutions -  <Link href={w}>Download</Link></Typography>
                  </Box>






<br/>
      
      </Box>
      </Paper>
      </Container>
      <br/>
      </div>
    )
  }
}

export default Downloads