import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

import 'firebase/compat/storage';
import "firebase/compat/database";

const firebaseConfig = {
    apiKey: "AIzaSyDfQz8NgCl9Ym4UVAZI-A8Gb_LOuoL0kOY",
    authDomain: "asoksecond-1313f.firebaseapp.com",
    projectId: "asoksecond-1313f",
    storageBucket: "asoksecond-1313f.appspot.com",
    messagingSenderId: "798918781549",
    appId: "1:798918781549:web:568bc6ad2a34e728c7ed64",
    measurementId: "G-MT7RPKWRLD"
  };
firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();

export const storage = firebase.storage();
export const database = firebase.database();

 
export default firebase;