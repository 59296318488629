import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import { Paper,Box } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgb(224,245,254)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>

    
    <Container>

    <Box sx={{backgroundColor:"#00337b",minHeight:130,}}>
    <Box>
    <br/>
    <Typography align='left' sx={{color:"white",fontSize:25,marginLeft:5,fontFamily:'sans-serif'}}>SAHU ACADEMY FAQ</Typography>
    <Typography align='left' sx={{color:"white",fontSize:17,marginLeft:5,fontFamily:'sans-serif'}}>Official sahu academy FAQ about optimal study practices for competitive exams like JEE/ Olympiads / KVPY /MHT CET</Typography>
    </Box>
    </Box>



    <br/>
    <Paper elevation={1} variant="outlined" square >
<Box>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:19}}>1. What courses does Sahu Academy offer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#1c446a',fontSize:17}}>
          Sahu Academy offers coaching for competitive exams like MHT CET, SAT, KVPY, AP, TestAS, and IISER.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:19}}>2. How can I enroll in Sahu Academy's coaching programs?
</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#1c446a',fontSize:17}}>
        You can enroll in Sahu Academy's coaching programs by filling the online registration form available on the website or by visiting any of our centers.

        </Typography>
      </AccordionDetails>
    </Accordion>




     
    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
      <Typography sx={{fontWeight:700, fontFamily:'serif',fontSize:19}}>3. What is the fee structure for different courses at Sahu Academy?</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#1c446a',fontSize:17}}>
      The fee structure for different courses at Sahu Academy varies. Please visit our website or contact our centers for more information.

      </Typography>
    </AccordionDetails>
  </Accordion>



  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
    <Typography sx={{fontWeight:700, fontFamily:'serif',fontSize:19}}>4. What is the duration of each batch at Sahu Academy, and when do they start?</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#1c446a',fontSize:17}}>
    The duration of each batch at Sahu Academy varies, and the start dates are different for each course. Please visit our website or contact our centers for more information.

    </Typography>
  </AccordionDetails>
</Accordion>


<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{fontWeight:700, fontFamily:'serif',fontSize:19}}>5. Does Sahu Academy offer scholarships for meritorious students?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#1c446a',fontSize:17}}>
          Yes, Sahu Academy offers scholarships for meritorious students scoring above 80% and 90% in the 10th standard.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography sx={{fontWeight:700, fontFamily:'serif',fontSize:19}}>6. What is the student-to-faculty ratio at Sahu Academy?
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#1c446a',fontSize:17}}>
        The student-to-faculty ratio at Sahu Academy is low, ensuring personalized attention to each student.

        </Typography>
      </AccordionDetails>
    </Accordion>


    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
      <Typography sx={{fontWeight:700, fontFamily:'serif',fontSize:19}}>7. Does Sahu Academy offer online or offline modes of instruction?

      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#1c446a',fontSize:17}}>
      Sahu Academy offers both online and offline modes of instruction, depending on the course selected.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
    <Typography sx={{fontWeight:700, fontFamily:'serif',fontSize:19}}>8. Are the faculty members at Sahu Academy qualified and experienced in their respective fields?

    </Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#1c446a',fontSize:17}}>
    Yes, the faculty members at Sahu Academy are highly qualified and experienced in their respective fields.
    </Typography>
  </AccordionDetails>
</Accordion>









      </Box>
      </Paper>
      <br/>
      </Container>
    </div>
  );
}