import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link ,TextField, Button, ButtonGroup,CircularProgress} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import croppedlogo from "../src/Lg.png"
import firebase from  "../src/firebase"
import moment from 'moment';

import Slide from '@mui/material/Slide';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';











export class Registrationforentranceexam extends Component {


constructor(props) {
  super(props)

  this.state = {
     name:'',
parent:'',
email:'',
mobile:'',
secondmobile:'',
course:'',
hometown:'',
circle:false,
enquery:false,
  }
  this.handleChange=this.handleChange.bind();
}
handleChange=(e)=>{
this.setState({[e.target.name]:e.target.value})
}

  render() {
    return (
      <div>
      <Box sx={{backgroundColor:"#e9efef"}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:30,md:30},fontFamily:'serif'}}>Registration Form</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#fff',display:'flex',justifyContent:'center'}}>
      <Grid container >
      <Grid item xs={12} sm={12} md={6} lg={6}>
      <Box sx={{backgroundColor:'#00337b',minHeight:{xs:200,sm:200,md:600,lg:600}}}>
<br/>
      <Box sx={{display:'flex',justifyContent:'center'}} >
  <img src={croppedlogo} style={{height:120,objectFit:'contain',}}/>
  </Box>
         
  <Box sx={{marginLeft:7,marginRight:7,display:{xs:'none',sm:'none',md:'block',lg:'block'}}}>
  <Typography align='center' sx={{fontSize:30,color:'white',fontWeight:600}}>Top 15 student  will get </Typography>
  <Box sx={{backgroundColor:'#ffcc01'}}>
  <Typography align='center'sx={{m:1,fontSize:30,color:'blue',fontWeight:600}}>100% scholarships</Typography>
  </Box>
  <Typography align='center' sx={{fontSize:30,color:'white',fontWeight:600}}>on Coachining</Typography>
  <br/>
  
  <Typography align='center' sx={{fontSize:30,color:'white',fontWeight:600}}>FOR FREE COUNSELLING</Typography>
  <br/>
 

  <Box sx={{display:'flex',justifyContent:'center'}}>
  <Button variant="contained" sx={{backgroundColor:'#ffcc01',color:'blue'}} onClick={()=>this.setState({enquery:true})}>
  Enquire now
  </Button>
  </Box>
  
  
  </Box>

      </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box sx={{backgroundColor:'white',height:600,display:'flex',flexDirection:'column',marginLeft:{xs:1,sm:1,md:5,lg:5},marginRight:{xs:1,sm:1,md:5,lg:5}}}>
        <br/>
        <Typography align='center' sx={{m:2}}>Registration form </Typography>

        <TextField id="outlined-basic" onChange={this.handleChange} label="Enter name" variant="outlined" name="name" size='small' sx={{m:1,fontSize:10}}/>
        <TextField id="outlined-basic" onChange={this.handleChange} label="Enter parent name" variant="outlined" name='parent' size='small' sx={{m:1}}/>
        <TextField id="outlined-basic" onChange={this.handleChange} label="Enter your email id" variant="outlined"  size='small' name='email' sx={{m:1}}/>
        <TextField id="outlined-basic" onChange={this.handleChange}  label="Enter your primary mobile number" variant="outlined"  size='small' name='mobile' sx={{m:1}}/>
        <TextField id="outlined-basic" onChange={this.handleChange} label="Enter your second mobile number if exist" variant="outlined"  size='small' name='secondmobile' sx={{m:1}}/>
      
        <Autocomplete
        disablePortal
        value={this.course}
        onChange={(event, newValue) => {
          
this.setState({course:newValue.label})


        }}
        id="combo-box-demo"
        size='small'
        options={top100Films}
        sx={{m:1}}
        renderInput={(params) => <TextField {...params} label="select course " />}
      />

     

        <TextField id="outlined-basic" name='hometown' onChange={this.handleChange} label="Enter your home town " variant="outlined"  size='small' sx={{m:1}}/>
<br/>
{
  this.state.circle?<Box sx={{display:'flex',justifyContent:'center'}}><CircularProgress size={24} /> </Box>:null
}
<br/>
        <Button variant="contained"  onClick={()=>{

if(this.state.course!==""||this.state.email!==""||this.state.hometown!==""||this.state.mobile!==""||this.state.parent!=="")
{
  this.setState({circle:true})
   const key= firebase.database().ref("contactform").push().key
          firebase.database().ref("Registrationforadmission").child(key).set({
            name:this.state.name,
       course:this.state.course,
       email:this.state.email,
       hometown:this.state.hometown,
       mobile:this.state.mobile,
       parent:this.state.parent,
       secondmobile:this.state.secondmobile,
       date:moment().format('L'),
key:key,
          }).then((d)=>{
           this.setState({circle:false})
          //  this.setState({paragraph:[],paragraphhead:""})
              alert("data successfully placed");
              
             
          })
}else{
alert("Plz fill all fields")
}
         
       
       





        }}>Register For Admission</Button>


        
        </Box>
      </Grid>
    </Grid>






    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      <Box>
      <Dialog
open={this.state.enquery}
TransitionComponent={Transition}
keepMounted
onClose={this.handleClose}
aria-describedby="alert-dialog-slide-description"
>
<DialogContent sx={{backgroundColor:'#00337b',}}>

<CloseIcon sx={{color:'white'}} onClick={()=>this.setState({enquery:false})}/>


<Box sx={{backgroundColor:'#00337b',minHeight:{xs:200,sm:200,md:400,lg:400}}}>
<br/>
     
         
  <Box sx={{marginLeft:7,marginRight:7,display:{xs:'block',sm:'block',md:'block',lg:'block'}}}>
  <Box sx={{backgroundColor:'#ffcc01'}}>
  <Typography align='center'sx={{m:1,fontSize:25,color:'blue',fontWeight:600,fontFamily:'sans-serif'}}>Enquiry Form</Typography>
  </Box>
  <br/>
  
  <TextField sx={{backgroundColor:'white'}} onChange={this.handleChange} name='elocation' id="outlined-basic" size='small' fullWidth label="Location" variant="outlined" />
<br/><br/>
<TextField  sx={{backgroundColor:'white'}} onChange={this.handleChange} name='ename' id="outlined-basic" size='small' fullWidth label="Name" variant="outlined" />
<br/><br/>
<TextField  sx={{backgroundColor:'white'}} onChange={this.handleChange} name='enumber' id="outlined-basic" size='small' fullWidth label="Mobile Number" variant="outlined" />

<br/>
  <br/>
  <Box sx={{display:'flex',justifyContent:'center'}}>
  <Button variant="contained" sx={{backgroundColor:'#ffcc01',color:'blue'}} onClick={()=>{
    if(this.state.elocation!=="" || this.state.ename!==""||this.state.enumber!==""){
      const key= firebase.database().ref("enquire").push().key
       firebase.database().ref("enquire").child(key).set({
       location:this.state.elocation,
         name:this.state.ename,
         key:key,
         number:this.state.enumber,
         date:moment().format('L'),
       }).then((d)=>{
       
           alert("data successfully placed");
           window.location.reload()
       })
   
     }else{
       alert("All fields are complesary")
       this.setState({enquery:false})
     }


  }}>
  Enquire now
  </Button>
  
  </Box>

      </Box>





</Box>
</DialogContent>
</Dialog>
      
      
      </Box>
      </Box>
      </div>
    )
  }
}

export default Registrationforentranceexam


const top100Films = [
    { label: 'Compact', year: 1994 },
    { label: 'Comprehensive 2025 (JEE)', year: 1972 },
    { label: 'Comprehensive 2024 (JEE)', year: 1994 },
    { label: 'Foundation Basic', year: 1972 },
    { label: 'Foundation Accelerated', year: 1994 },
    { label: 'Pre Foundation Basic', year: 1972 },
    { label: 'Comprehensive 2023 (JEE)', year: 1994 },
    { label: 'Foundation Winter+Summer', year: 1972 },
    { label: 'Summer Foundation', year: 1972 },
  
  
  ]

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });