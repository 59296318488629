import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link ,TextField, Button, ButtonGroup,IconButton, CardMedia, Card} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import croppedlogo from "../src/Lg.png"

import croppedlogoc from "../src/croppedlogo.jpg"



import firebase from "../src/firebase"
import AppBar from '@mui/material/AppBar';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import JoditEditor from 'jodit-react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import JoinFullIcon from '@mui/icons-material/JoinFull';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import CampaignIcon from '@mui/icons-material/Campaign';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

import VpnLockIcon from '@mui/icons-material/VpnLock';
const drawerWidth = 240;













export class Login extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      condition:false,
      rr:1,
     paragraph:[],
     para:"",
    paragraphhead:"",
    blog:[],

    posterhead:'',
    posterimg:'',
    backdrop:false,
    posterdata:[],
    register:[],
    enquery:[],
    announcement:[],
    v:'',
    noticehead:"",

    vs:'',
    noticeheads:"",

    vss:'',
    noticeheadss:"",
    newsandevents : [],


username:"",
password:"",
sss:"",

cont:[],
career:[],
mmmm:false,



open:false,
    }
    
   
this.handleChange= this.handleChange.bind();
this.handleChangee=this.handleChangee.bind();
  }


  handleChange = (e) =>{
    this.setState({
        [e.target.name]:e.target.value,
    })
  }
  handleChangee = (e) =>{
    this.setState({
        [e.target.name]:e.target.files[0],
    })
  }





     
componentDidMount(){
var reff = firebase.database().ref().child("Blogs");
        let blog = [];
        reff.once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
             
              var childData = childSnapshot.val();
              
              blog.push(childData);

            this.setState({blog:blog})
             
              // ...
            });
          });
          












          var rc = firebase.database().ref().child("career");
          let m = [];
          rc.once('value', (snapshot) => {
              snapshot.forEach((childSnapshot) => {
               
                var childData = childSnapshot.val();
                
                m.push(childData);
  
              this.setState({career:m})
               
                // ...
              });
            });

            var rb = firebase.database().ref().child("contactform");
            let cont = [];
            rb.once('value', (snapshot) => {
                snapshot.forEach((childSnapshot) => {
                 
                  var childData = childSnapshot.val();
                  
                  cont.push(childData);
    
                this.setState({cont:cont})
                 
                  // ...
                });
              });
  
















         


            var r = firebase.database().ref().child("Registrationforadmission");
            let register = [];
            r.once('value', (snapshot) => {
                snapshot.forEach((childSnapshot) => {
                 
                  var childData = childSnapshot.val();
                  
                  register.push(childData);
    
                this.setState({register:register})
                 
                  // ...
                });
              });
  

              var rrr = firebase.database().ref().child("enquire");
              let enquery = [];
              rrr.once('value', (snapshot) => {
                  snapshot.forEach((childSnapshot) => {
                   
                    var childData = childSnapshot.val();
                    
                    enquery.push(childData);
      
                  this.setState({enquery:enquery})
                   
                    // ...
                  });
                });
    



                var rrrrr = firebase.database().ref().child("announcement");
                let announcement = [];
                rrrrr.once('value', (snapshot) => {
                    snapshot.forEach((childSnapshot) => {
                     
                      var childData = childSnapshot.val();
                      
                      announcement.push(childData);
        
                    this.setState({announcement:announcement})
                     
                      // ...
                    });
                  });
      


                  var rrrrrr = firebase.database().ref().child("newsandevents");
                  let newsandevents = [];
                  rrrrrr.once('value', (snapshot) => {
                      snapshot.forEach((childSnapshot) => {
                       
                        var childData = childSnapshot.val();
                        
                        newsandevents.push(childData);
          
                      this.setState({newsandevents:newsandevents})
                       
                        // ...
                      });
                    });

            
                var rrrr = firebase.database().ref().child("highlight");
                let highlight = [];
                rrrr.once('value', (snapshot) => {
                    snapshot.forEach((childSnapshot) => {
                     
                      var childData = childSnapshot.val();
                      
                      highlight.push(childData);
        
                    this.setState({highlight:highlight})
                     
                      // ...
                    });
                  });
      
              





}






blog=(props)=>{
  this.props.navigate("/funtest")
}

mm=()=>{
  const d = this.state.para;
  console.log(this.state.ss);
  this.setState({
 paragraph:this.state.paragraph.concat([{ss:this.state.ss}])
  });
  
}



 sm=()=>{
  alert("ok")
 }




save=()=>{
  if(this.state.paragraphhead!=="" && this.state.paragraph.length!==0){
   const key= firebase.database().ref("Blogs").push().key
    firebase.database().ref("Blogs").child(key).set({
    head:this.state.paragraphhead,
      paragraph:this.state.paragraph,
      key:key,
    }).then((d)=>{
    
      this.setState({paragraph:[],paragraphhead:""})
        alert("data successfully placed");
        window.location.reload()
    })

  }else{
    alert("All fields are complesary")
  }
}



  render(props) {
   
    return (
      <div>
      <Box sx={{backgroundColor:"#e9efef"}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:30,md:30},fontFamily:'serif'}}>Login Form</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#fff',display:'flex',justifyContent:'center'}}>
      <Grid container >
      <Grid item xs={12} sm={12} md={6} lg={6}>
      <Box sx={{backgroundColor:'#00337b',minHeight:{xs:200,sm:200,md:400,lg:400}}}>
<br/>
      <Box sx={{display:'flex',justifyContent:'center'}} >
  <img src={croppedlogo} style={{height:120,objectFit:'contain',}}/>
  </Box>
         
  <Box sx={{marginLeft:7,marginRight:7,display:{xs:'none',sm:'none',md:'block',lg:'block'}}}>
  <Typography align='center' sx={{fontSize:25,color:'white',fontWeight:600}}>Top 15 student  will get </Typography>
  <Box sx={{backgroundColor:'#ffcc01'}}>
  <Typography align='center'sx={{m:1,fontSize:25,color:'blue',fontWeight:600}}>100% scholarships</Typography>
  </Box>
  <Typography align='center' sx={{fontSize:25,color:'white',fontWeight:600}}>on Coachining</Typography>
  <br/>
  
  <Typography align='center' sx={{fontSize:25,color:'white',fontWeight:600}}>FOR FREE COUNSELLING</Typography>
  <br/>
  <Box sx={{display:'flex',justifyContent:'center'}}>
  <Button variant="contained" sx={{backgroundColor:'#ffcc01',color:'blue'}}>
  Enquire now
  </Button>
  </Box>
  </Box>

      </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box sx={{backgroundColor:'white',height:400,display:'flex',flexDirection:'column',marginLeft:{xs:1,sm:1,md:5,lg:5},marginRight:{xs:1,sm:1,md:5,lg:5}}}>
        <br/>
        <Typography align='center' sx={{m:2}}>Login form </Typography>

        <TextField id="outlined-basic" label="Username" variant="outlined" onChange={this.handleChange} name="username"  size='small' sx={{m:1,fontSize:10}}/>
        <TextField id="outlined-basic" label="Enter password" variant="outlined" onChange={this.handleChange}  name="password"  size='small' sx={{m:1}}/>
        <Autocomplete
      disablePortal
      value={this.state.sss}
        onChange={(event, newValue) => {
          this.setState({sss:newValue.label})
        }}
      id="combo-box-demo"
      size='small'
      options={date}
      sx={{m:1}}

      renderInput={(params) => <TextField {...params} label="Login as student or admin" />}
    />
<br/>
        <Button variant="contained" sx={{width:200}} onClick={()=>{
        if(this.state.sss==="Admin"){

          firebase.auth().signInWithEmailAndPassword( this.state.username,this.state.password)
          .then((userCredential) => {
           this.setState({open:true})
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            alert(errorMessage)
          });



        }else{



alert("Accept Admin Other Cannot Login")



        }
      
      
      
      } }>Login</Button>


        
        </Box>
      </Grid>
    </Grid>






    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>



<Box sx={{display:{xs:"none",sm:"flex"}}}>

<Dialog
fullScreen
open={this.state.open} ///open this.state
onClose={this.handleClose}

>

<Box>


<Box sx={{ display: 'flex' }}>
<CssBaseline />
<AppBar
  position="fixed"
  sx={{
    width: { sm: `calc(100% - ${drawerWidth}px)` },
    ml: { sm: `${drawerWidth}px` },
    backgroundColor:'#00337b'
  }}
>
  <Toolbar>
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={()=>this.setState({mmmm:true})}
      sx={{ mr: 2, display: { sm: 'none' } }}
    >
      <MenuIcon />
    </IconButton>
    <Typography variant="h6" noWrap component="div">
      Admin Dashboard
    </Typography>




    <Box sx={{flexGrow:1}}>
   
  <Typography align="right" onClick={()=>this.setState({open:false})}>Close</Typography>
    </Box>
   



  </Toolbar>
</AppBar>




{
  this.state.mmmm?
  <Paper elevation={5} sx={{width:'60%',height:"100%",backgroundColor:'blue',display:'flex',position:'fixed',top:0,left:0,overflow:'auto'}}>
 <Box sx={{display:'flex',maxHeight:900,flexDirection:'column',m:2,overflow:'auto'}}>
 <Box onClick={()=>this.setState({mmmm:false})} >
 <CloseIcon sx={{color:'black',height:30,width:30,mt:5}} onClick={()=>{this.setState({mmmm:false})}} />
 </Box>
 
<List>

{[
  {
    name:"Enquiry",
    icon:<HelpIcon />,
    color:"",
    value:1
  },
  {
    name:"Highlights",
    icon:<JoinFullIcon />,
    color:"",
    value:2
  },
  {
    name:"News & Events",
    icon:<AcUnitIcon />,
    color:"",
    value:3
  },
  {
    name:"Announcement",
    icon:<CampaignIcon />,
    color:"",
    value:4
  },
  {
    name:"Blogs",
    icon:<BorderColorIcon />,
    color:"",
    value:5
  },
  {
    name:"Register",
    icon:<AppRegistrationIcon />,
    color:"",
    value:6
  },
  {
    name:"Contacts",
    icon:<ConnectWithoutContactIcon />,
    color:"",
    value:7
  },
  {
    name:"Career",
    icon:<VpnLockIcon />,
    color:"",
    value:8
  },

].map((text, index) => (
 
  <ListItem key={text} disablePadding >
    <ListItemButton onClick={()=>this.setState({rr:text.value})} >
      <ListItemIcon>
        {text.icon}
      </ListItemIcon>
      <ListItemText sx={{fontFamily:'serif'}} primary={text.name} />
    </ListItemButton>
  </ListItem>
  
))}











</List>
</Box>
</Paper>:null}










<Box
  component="nav"
  sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
  aria-label="mailbox folders"
  
>
  {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
  <Drawer
 
    variant="temporary"
    open={true}
    
    
    sx={{
      display: { xs: 'block', sm: 'block',md:'none',lg:'none' },
      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
    }}
  >
  <div>
  <Box sx={{display:'flex',justifyContent:'center',width:'100%',backgroundColor:'white'}} >
  <img src={croppedlogoc} style={{height:70,objectFit:'contain',}}/>
  </Box>
         
    <Divider />
    <List>
      {[
        {
          name:"Enquiry",
          icon:<HelpIcon />,
          color:"",
          value:1
        },
        {
          name:"Highlights",
          icon:<JoinFullIcon />,
          color:"",
          value:2
        },
        {
          name:"News & Events",
          icon:<AcUnitIcon />,
          color:"",
          value:3
        },
        {
          name:"Announcement",
          icon:<CampaignIcon />,
          color:"",
          value:4
        },
        {
          name:"Blogs",
          icon:<BorderColorIcon />,
          color:"",
          value:5
        },
        {
          name:"Register",
          icon:<AppRegistrationIcon />,
          color:"",
          value:6
        },
        {
          name:"Contacts",
          icon:<ConnectWithoutContactIcon />,
          color:"",
          value:7
        },
        {
          name:"Career",
          icon:<VpnLockIcon />,
          color:"",
          value:8
        },
      
      ].map((text, index) => (
       
        <ListItem key={text} disablePadding >
          <ListItemButton onClick={()=>this.setState({rr:text.value})} >
            <ListItemIcon>
              {text.icon}
            </ListItemIcon>
            <ListItemText sx={{fontFamily:'serif'}} primary={text.name} />
          </ListItemButton>
        </ListItem>
        
      ))}
    </List>
   
   
  </div>



  </Drawer>
  <Drawer
    variant="permanent"
    sx={{
      display: { xs: 'none', sm: 'block' },
      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }, 
    }}
    open
  >

  <div>
  <Box sx={{display:'flex',justifyContent:'center',width:'100%',backgroundColor:'white'}} >
  <img src={croppedlogoc} style={{height:70,objectFit:'contain',}}/>
  </Box>
         
    <Divider />
    <List>
      {[
        {
          name:"Enquiry",
          icon:<HelpIcon />,
          color:"",
          value:1
        },
        {
          name:"Highlights",
          icon:<JoinFullIcon />,
          color:"",
          value:2
        },
        {
          name:"News & Events",
          icon:<AcUnitIcon />,
          color:"",
          value:3
        },
        {
          name:"Announcement",
          icon:<CampaignIcon />,
          color:"",
          value:4
        },
        {
          name:"Blogs",
          icon:<BorderColorIcon />,
          color:"",
          value:5
        },
        {
          name:"Register",
          icon:<AppRegistrationIcon />,
          color:"",
          value:6
        },
        {
          name:"Contacts",
          icon:<ConnectWithoutContactIcon />,
          color:"",
          value:7
        },
        {
          name:"Career",
          icon:<VpnLockIcon />,
          color:"",
          value:8
        },
      
      ].map((text, index) => (
        <ListItem key={text} disablePadding >
          <ListItemButton onClick={()=>this.setState({rr:text.value})}>
            <ListItemIcon>
              {text.icon}
            </ListItemIcon>
            <ListItemText sx={{fontFamily:'serif'}} primary={text.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
   
   
  </div>

    



  </Drawer>
</Box>
<Box
  component="main"
  sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
>
  <Toolbar />
  
  
  {
    this.state.rr===5?
    <Box>
    <br/>
    <br/>
    
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
    <TextField fullWidth size='small' name='paragraphhead' onChange={this.handleChange} label="Enter Blogs Heading" id="fullWidth" />
    <br/>
    <br/>
    { this.state.paragraph? this.state.paragraph.map((re,index)=>{
     return <Box sx={{display:'flex',flexDirection:'row'}}>
     
     <Box sx={{border:0.5,borderColor:'grey',marginBottom:1,width:'100%'}}>
     <Typography sx={{fontFamily:'serif'}}>{re.ss}</Typography>
     </Box>
      </Box>
    }):null
    }
    
    <br/>
    <br/>
    <TextField
              id="outlined-multiline-static"
              label="Write here blogs paragraph"
              multiline
              fullWidth
              name="ss"
             onChange={this.handleChange}
              rows={3}
              defaultValue={this.state.para}
            />
            <br/>
            <br/>
    
            <Button variant="contained" onClick={this.mm}>Add paragraph</Button>
    <br/>
    <br/>
    
    <Button variant="contained" sx={{width:'100%'}} onClick={this.save}>Submit to Database</Button>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} >
       <Box sx={{height:420,backgroundColor:'#e8eeee'}}>
       <Box sx={{maxHeight:400,overflow:'scroll',scrollbarWidth:"1",mb:2,overflowX:'hidden',  "&::-webkit-scrollbar": {display: "none"}}}>
       
    
       {
        this.state.blog?this.state.blog.map((rr,i)=>(
          <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'center'}}>
       <ClearIcon sx={{height:20,width:20,color:"black",mt:0.5}}  onClick={()=>firebase.database().ref('Blogs').child(rr.key).remove()}/>
       <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:10,sm:10,md:12,lg:12,},width:'100%',fontFamily:'sans-serif','&:hover': {borderBottom:'1px solid red',color:'red'}}}>{rr.head}</Typography>
       </Box>
    
        )):null
       }
    
  
    </Box>
       </Box>
      </Grid>
     
    </Grid>
    
    
    
    </Box>:null
    
    }
    




    {this.state.rr===2?
      <Box>
    <br/>
    
    <Card sx={{minHeight:200,backgroundColor:'white'}}>
    <Box sx={{ margin:5}}>
    <Box sx={{backgroundColor:'#2f3f8c'}}>
      <Typography align='center' sx={{color:'white',fontWeight:700,fontSize:18}}>Important Highlights</Typography>
      </Box>
    <br/>
    <br/>
    
    
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
       
      <TextField size='small' fullWidth name='noticehead' onChange={this.handleChange} label="Enter notice Heading" id="fullWidth" />
      <br/>
      <br/>
      <Typography>Type text below</Typography>
      
      
      <JoditEditor
      ref={this.editor}
      value={this.state.v}
      
      onChange={newContent =>this.setState({v:newContent})}
      />
      
      <br/>
      
      <Button variant='contained' onClick={()=>{
      if(this.state.noticehead!==""&&this.state.v!==""){
        const key= firebase.database().ref("Posterdata").push().key
        firebase.database().ref("highlight").child(key).set({
        head:this.state.noticehead,
        paragraph:this.state.v,
          key:key,
        }).then((d)=>{
          this.setState({backdrop:false})
        //  this.setState({paragraph:[],paragraphhead:""})
            alert("data successfully placed");
            
        })
      }else{
        alert("plz fill all field")
      }
        
      
      }
      
      }>Save</Button>
      
    
    
      </Grid>
      <Grid item xs={12} sm={12}  md={4} lg={4} >
      <Box sx={{height:420,backgroundColor:'#e8eeee'}}>
      <Box  sx={{maxHeight:400,overflow:'scroll',scrollbarWidth:"1",mb:2,overflowX:'hidden',  "&::-webkit-scrollbar": {display: "none"}}}>
      
    
      {
       this.state.highlight?this.state.highlight.map((rr,i)=>(
         <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'center'}}>
      <ClearIcon sx={{height:20,width:20,color:"black",mt:0.5}}  onClick={()=>firebase.database().ref('highlight').child(rr.key).remove()}/>
      <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:10,sm:10,md:12,lg:12,},width:'100%',fontFamily:'sans-serif','&:hover': {borderBottom:'1px solid red',color:'red'}}}>{rr.head}</Typography>
      </Box>
    
       )):null
      }
    
    
    
    
    </Box>
      </Box>
      </Grid>
    </Grid>
    
    
    </Box>
    </Card>
     
    <br/>
    
      </Box>:null
    }
    
    
    {this.state.rr===3?
      <Box>
    <br/>
    <Card sx={{minHeight:200,backgroundColor:'white'}}>
    <Box sx={{ margin:5}}>
    <Box sx={{backgroundColor:'#2f3f8c'}}>
      <Typography align='center' sx={{color:'white',fontWeight:700,fontSize:18}}>News & Events</Typography>
      </Box>
    <br/>
    <br/>
    
    
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
       
      <TextField size='small' fullWidth name='noticeheadss' onChange={this.handleChange} label="Enter News And Events Heading" id="fullWidth" />
      <br/>
      <br/>
      <Typography>Type text below</Typography>
      
      
      <JoditEditor
      ref={this.editor}
      value={this.state.vss}
      
      onChange={newContent =>this.setState({vss:newContent})}
      />
      
      <br/>
      
      <Button variant='contained' onClick={()=>{
      if(this.state.noticeheadss!==""&&this.state.vss!==""){
        const key= firebase.database().ref("Posterdata").push().key
        firebase.database().ref("newsandevents").child(key).set({
        head:this.state.noticeheadss,
        paragraph:this.state.vss,
          key:key,
        }).then((d)=>{
          this.setState({backdrop:false})
        //  this.setState({paragraph:[],paragraphhead:""})
            alert("data successfully placed");
            
        })
      }else{
        alert("plz fill all field")
      }
        
      
      }
      
      }>Save</Button>
      
    
    
      </Grid>
      <Grid item xs={12} sm={12}  md={4} lg={4} >
      <Box sx={{height:420,backgroundColor:'#e8eeee'}}>
      <Box  sx={{maxHeight:400,overflow:'scroll',scrollbarWidth:"1",mb:2,overflowX:'hidden',  "&::-webkit-scrollbar": {display: "none"}}}>
      
    
      {
       this.state.newsandevents?this.state.newsandevents.map((rr,i)=>(
         <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'center'}}>
      <ClearIcon sx={{height:20,width:20,color:"black",mt:0.5}}  onClick={()=>firebase.database().ref('newsandevents').child(rr.key).remove()}/>
      <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:10,sm:10,md:12,lg:12,},width:'100%',fontFamily:'sans-serif','&:hover': {borderBottom:'1px solid red',color:'red'}}}>{rr.head}</Typography>
      </Box>
    
       )):null
      }
    
    </Box>
      </Box>
      </Grid>
    </Grid>
  
    </Box>
    </Card>
  
    <br/>
    
      </Box>:null
    }
    
    
    {this.state.rr===4?
      <Box>
    <br/>
    <Card sx={{minHeight:200,backgroundColor:'white'}}>
    <Box sx={{ margin:5}}>
    <Box sx={{backgroundColor:'#2f3f8c'}}>
      <Typography align='center' sx={{color:'white',fontWeight:700,fontSize:18}}>Announcement</Typography>
      </Box>
    <br/>
    <br/>
    
    
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
       
      <TextField size='small' fullWidth name='noticeheads' onChange={this.handleChange} label="Enter Announcememnt Heading" id="fullWidth" />
      <br/>
      <br/>
      <Typography>Type text below</Typography>
      
      
      <JoditEditor
      ref={this.editor}
      value={this.state.vs}
      
      onChange={newContent =>this.setState({vs:newContent})}
      />
      
      <br/>
      
      <Button variant='contained' onClick={()=>{
      if(this.state.noticeheads!==""&&this.state.vs!==""){
        const key= firebase.database().ref("Posterdata").push().key
        firebase.database().ref("announcement").child(key).set({
        head:this.state.noticeheads,
        paragraph:this.state.vs,
          key:key,
        }).then((d)=>{
          this.setState({backdrop:false})
        //  this.setState({paragraph:[],paragraphhead:""})
            alert("data successfully placed");
            
        })
      }else{
        alert("plz fill all field")
      }
        
      
      }
      
      }>Save</Button>
      
    
    
      </Grid>
      <Grid item xs={12} sm={12}  md={4} lg={4} >
      <Box sx={{height:420,backgroundColor:'#e8eeee'}}>
      <Box  sx={{maxHeight:400,overflow:'scroll',scrollbarWidth:"1",mb:2,overflowX:'hidden',  "&::-webkit-scrollbar": {display: "none"}}}>
      
    
      {
       this.state.announcement?this.state.announcement.map((rr,i)=>(
         <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'center'}}>
      <ClearIcon sx={{height:20,width:20,color:"black",mt:0.5}}  onClick={()=>firebase.database().ref('announcement').child(rr.key).remove()}/>
      <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:10,sm:10,md:12,lg:12,},width:'100%',fontFamily:'sans-serif','&:hover': {borderBottom:'1px solid red',color:'red'}}}>{rr.head}</Typography>
      </Box>
    
       )):null
      }
    
    
    
    
    </Box>
      </Box>
      </Grid>
    </Grid>
    
    
    </Box>
    </Card>
    
    
     
    <br/>
    
      </Box>:null
    }
    
    
    {this.state.rr===6?
    <Box>
      <Box sx={{backgroundColor:'#2f3f8c'}}>
      <Typography align='center' sx={{color:'white',fontWeight:700,fontSize:18}}>Registered User</Typography>
      </Box>
      <br/>
    
    
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell  align="center">Name</StyledTableCell>
            <StyledTableCell align="center">Mobile_number</StyledTableCell>
            <StyledTableCell align="center">Email id</StyledTableCell>
            <StyledTableCell align="center">Date</StyledTableCell>
            <StyledTableCell align="center">Other mobile_num</StyledTableCell>
            <StyledTableCell align="center">Parent_name</StyledTableCell>
            <StyledTableCell align="center">Town</StyledTableCell>
            <StyledTableCell align="center">Course</StyledTableCell>
            <StyledTableCell align="center">Button</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.register?this.state.register.map((row) => (
            <StyledTableRow>
              <StyledTableCell component="th" align="center" scope="row">{row.name}</StyledTableCell>
              <StyledTableCell align="center">{row.mobile}</StyledTableCell>
              <StyledTableCell align="center">{row.email}</StyledTableCell>
              <StyledTableCell align="center">{row.date}</StyledTableCell>
              <StyledTableCell align="center">{row.secondmobile}</StyledTableCell>
              <StyledTableCell align="center">{row.parent} </StyledTableCell>
              <StyledTableCell align="center">{row.hometown}</StyledTableCell>
              <StyledTableCell align="center">{row.course}</StyledTableCell>
              
              <StyledTableCell align="center"><Button onClick={()=>firebase.database().ref().child("Registrationforadmission").child(row.key).remove()}>Delete</Button></StyledTableCell>
            </StyledTableRow>
          )):null
        }
        </TableBody>
      </Table>
    </TableContainer>
    
    
    
    
    
    <br/>
    <br/>
    <br/>
    </Box>:null
    }
    
    {
    this.state.rr==1?
    <Box>
      
    <br/>
    <Box sx={{backgroundColor:'#2f3f8c'}}>
    <Typography align='center' sx={{color:'white',fontWeight:700,fontSize:18}}>Enquired data</Typography>
    
    </Box>
    <br/>
    
    
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell  align="center">Name</TableCell>
          <TableCell align="center">Mobile Number</TableCell>
          <TableCell align="center">Location</TableCell>
          <TableCell align="center">Date</TableCell>
          <TableCell align="center">Button</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {this.state.enquery?this.state.enquery.map((row) => (
          <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row" align="center" >
              {row.name}
            </TableCell>
            <TableCell align="center">{row.number}</TableCell>
            <TableCell align="center">{row.location}</TableCell>
            <TableCell align="center">{row.date}</TableCell>
            <TableCell align="center"><Button onClick={()=>
              firebase.database().ref().child("enquire").child(row.key).remove()
            
            }>Delete</Button></TableCell>
          </TableRow>
        )):null
      }
      </TableBody>
    </Table>
    </TableContainer>
    
    
    

    </Box>:null
    
    
    
    }
    
    {
      this.state.rr==7?
      <Box>
        
      <br/>
      <Box sx={{backgroundColor:'#2f3f8c'}}>
      <Typography align='center' sx={{color:'white',fontWeight:700,fontSize:18}}>Contact</Typography>
      
      </Box>
      <br/>
      
      
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell  align="center">Name</TableCell>
            <TableCell align="center">Mobile Number</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Button</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.cont?this.state.cont.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center" >
                {row.name}
              </TableCell>
              <TableCell align="center">{row.contactno}</TableCell>
              <TableCell align="center">{row.email}</TableCell>
              <TableCell align="center">{row.type}</TableCell>
              <TableCell align="center"><Button onClick={()=>
                firebase.database().ref().child("contactform").child(row.key).remove()
              
              }>Delete</Button></TableCell>
            </TableRow>
          )):null
        }
        </TableBody>
      </Table>
      </TableContainer>
      
      </Box>:null
      
      }




      {
        this.state.rr==8?
        <Box>
          
        <br/>
        <Box sx={{backgroundColor:'#2f3f8c'}}>
        <Typography align='center' sx={{color:'white',fontWeight:700,fontSize:18}}>Career</Typography>
        
        </Box>
        <br/>
        
        
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell  align="center">Name</TableCell>
              <TableCell align="center">Mobile Number</TableCell>
              <TableCell align="center">Exprience</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Button</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.career?this.state.career.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center" >
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.mobileno}</TableCell>
                <TableCell align="center">{row.exprince}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center"><Button onClick={()=>
                  firebase.database().ref().child("career").child(row.key).remove()
                
                }>Delete</Button></TableCell>
              </TableRow>
            )):null
          }
          </TableBody>
        </Table>
        </TableContainer>
        
 
        </Box>:null
        
      
        }


</Box>
</Box>


</Box>
</Dialog>


</Box>
      </div>
    )
  }
}

export default Login


const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },]

    const date = [
        { label: 'Student', year: 1994 },
        { label: 'Admin', year: 1994 }
    ]
       

    




    const rows = [
      {
       id:";el;l",
       mm:"w;lpepl"
      },
      {
       id:";el;l",
       mm:"w;lpepl"
      },
      {
       id:";el;l",
       mm:"w;lpepl"
      }
     
     
     
     
     ];
     

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));



  




    const drawer = (
      <div>
      <Toolbar/>
        <Divider />
        <List>
          {[
            {
              name:"Enquiry",
              icon:<HelpIcon />,
              color:"",
              value:1
            },
            {
              name:"Highlights",
              icon:<JoinFullIcon />,
              color:"",
              value:2
            },
            {
              name:"News & Events",
              icon:<AcUnitIcon />,
              color:"",
              value:3
            },
            {
              name:"Announcement",
              icon:<CampaignIcon />,
              color:"",
              value:4
            },
            {
              name:"Blogs",
              icon:<BorderColorIcon />,
              color:"",
              value:5
            },
            {
              name:"Register",
              icon:<AppRegistrationIcon />,
              color:"",
              value:6
            },
            {
              name:"Contacts",
              icon:<ConnectWithoutContactIcon />,
              color:"",
              value:7
            },
            {
              name:"Career",
              icon:<VpnLockIcon />,
              color:"",
              value:8
            },
          
          ].map((text, index) => (
            <ListItem key={text} disablePadding >
              <ListItemButton >
                <ListItemIcon>
                  {text.icon}
                </ListItemIcon>
                <ListItemText sx={{fontFamily:'serif'}} primary={text.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
       
       
      </div>
    );
  





    