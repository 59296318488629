import Carousel from 'react-bootstrap/Carousel';


import two from "../src/banner.jpg"
function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100  "
          src={two}
          alt="First slide"
style={{height:500}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={two}
          alt="Second slide"
          style={{height:500}}
        />

        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={two}
          alt="Third slide"
          style={{height:500}}
        />

       
       
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;