import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
   import ss from "../src/download.jpg"
   import Avatar from '@mui/material/Avatar';
import { Container } from '@mui/system';
import mm from "../src/facultyimg/mm.png"
import nn from "../src/facultyimg/nn.png"
import pra from "../src/praduman.png"
 import mc from "../src/facultyimg/mc.png"
 import nim from "../src/facultyimg/nim.png"
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:mm,
    name:"Siddhant Singvi",
    content:"Manish Sahu Sir is an amazing teacher. His guidance has helped me secure a good rank in JEE exams. He was always available for help and clearing doubts. His adequate guidelines helped me in developing proper skill and strategy. A great deal of credit goes to Manish sir for whatever I have achieved."
  },
  {
    label: 'Bird',
    imgPath:nn,
    name:"Ashita Hiregoudar",
    content:"First of all, I would like to thanks the Sahu Academy team for their heartiest contribution to make my way towards Success. I found Manish Sahu Sir the best faculty I have ever seen. They understood my pattern of learning, and taught me accordingly; never forcing or pressurizing me to do anything."
  },
  {
    label: 'Birddf',
    imgPath:nim,
    name:"Pradyumna Mishra",
    content:"I was struggling with the SAT exam and was not sure how to improve my scores. That's when I joined Sahu Academy's SAT coaching program, and it completely transformed my approach to the exam. The faculty members were very supportive and provided personalized attention to each student. The study materials were excellent, and the online classes were very interactive. Thanks to Sahu Academy, I was able to score an excellent SAT score and secure admission to my dream college in the US."
  },
  {
    label: 'Birgngd',
    imgPath:mc,
    name:"Pranav rathore",
    content:"I was always passionate about pursuing a career in medicine, but I was struggling with the NEET exam. That's when I joined Sahu Academy's NEET coaching program, and it completely transformed my approach to the exam. The faculty members were highly knowledgeable and experienced, and they helped me understand difficult concepts with ease. The study materials were comprehensive and included mock tests that helped me prepare effectively. The online classes were very convenient, and I was able to clear all my doubts through the doubt-clearing sessions. Thanks to Sahu Academy, I was able to score an excellent NEET rank and secure admission to a top-ranked medical college."
  },





];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    
    <Box sx={{  flexGrow: 1 }}>
    <Container>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 0,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
       
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
      {images.map((step, index) => (
        
        <div key={step.label} >
         <Box sx={{display:'flex',justifyContent:'center',maxHeight:900}}>
         <Box sx={{display:'flex',alignItems:'center',flexDirection:{xs:'column',sm:'column',md:'row'},mt:2,mb:2}}>
         <Avatar
       alt="Remy Sharp"
       src={step.imgPath}
       sx={{ width:{xs:130,sm:130,md:170,lg:170},m:1, height:{xs:130,sm:130,md:170,lg:170},objectFit:'contain',background:'transparent' }}
/>
<Box sx={{marginLeft:3}}>
<Typography align='justify'  sx={{mb:2,color:'white',fontFamily:'serif',fontSize:{xs:16,sm:16,md:18}}}>{step.content}</Typography>

<Typography sx={{color:'#ffcc01',fontSize:15,fontStyle:'italic',mt:2}} >{step.name}</Typography>



</Box>

         </Box>
         </Box>
         </div>
        
      ))}
      </AutoPlaySwipeableViews>
     
      </Container>
    </Box>
    
  );
}

export default SwipeableTextMobileStepper;