import React, { Component } from 'react'
import { Typography,Divider } from '@mui/material'
import { Box, Container } from '@mui/system'
import two from '../assets/two.jpg'
import boy from "../boy.png"
import CircleIcon from '@mui/icons-material/Circle';
export class Jeeadvance extends Component {
  render() {
    return (
        <div>
        <Box sx={{backgroundImage:`url(${two})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
        <br/>
        
        <Typography align='center' sx={{fontSize:{xs:30,sm:30,md:36,lg:36},fontFamily:'serif'}}>JEE-Advance</Typography>
        <br/>
        <Container maxWidth='lg'>
        <Box sx={{backgroundColor:"#00337b",minHeight:300}}>
        <br/>
        

        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
        <Box sx={{display:'flex',marginLeft:2}}>
         <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
        </Box>
        <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>For JEE Advanced, our success rate is an impressive 65%. We understand that JEE Advanced can be challenging, which is why we provide our students with the tools they need to succeed.

        </Typography>
        </Box>
        <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
     
        

        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
        <Box sx={{display:'flex',marginLeft:2}}>
         <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
        </Box>
        <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>For Enquiry, contact us on 98508 44937

        </Typography>
        </Box>
        <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
     
        






        

        </Box>
        </Container>
        <br/>
        <br/>
        </Box>
        </div>
    )
  }
}

export default Jeeadvance