import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link, IconButton } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
// Comperhensivetwentyfour.js
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
export class Center extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundColor:"#e9efef"}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:30,md:30},fontFamily:'serif'}}>Center</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#fff',minHeight:300}}>
   <br/>

<Box>

<Grid container spacing={1}>
  <Grid item xs={12} sm={12} md={4} lg={4}>
   <Box sx={{backgroundColor:'#e8eeee',border:2,m:1,"&:hover": {
    borderColor:'#e89323'
  }}}>
   <Box sx={{height:200,backgroundColor:'#e8eeee',margin:2}}>
   <Box sx={{backgroundColor:'#00337b'}}>
   <Typography align='center' sx={{m:2,color:'white',fontFamily:'serif'}}>Shivaji Nagar </Typography>
   </Box>
   
   <Typography align='center' sx={{m:2,color:'#00337b'}}>9850844937</Typography>

    <Box sx={{display:'flex',justifyContent:'center',}}>
    <IconButton color="primary" aria-label="add to shopping cart">
    <AddIcCallIcon sx={{height:26,width:26,marginRight:2,color:'#00337b'}}/>
</IconButton>
    </Box>
    <Typography align='center' sx={{fontFamily:'serif'}}>Ghole Road Shivaji Nagar,Pune</Typography>
   </Box>

   </Box>
  </Grid>
  
  <Grid item xs={12} sm={12} md={4} lg={4}>
  <Box sx={{backgroundColor:'#e8eeee',border:2,m:1, "&:hover": {
    borderColor:'#e89323'
  }}}>
   <Box sx={{height:200,backgroundColor:'#e8eeee',margin:2}}>
   <Box sx={{backgroundColor:'#00337b'}}>
   <Typography align='center' sx={{m:2,color:'white',fontFamily:'serif'}}>Aundh </Typography>
   </Box>
   
   <Typography align='center' sx={{m:2,color:'#00337b'}}>9850844937</Typography>

    <Box sx={{display:'flex',justifyContent:'center',}}>
    <IconButton color="primary" aria-label="add to shopping cart">
    <AddIcCallIcon sx={{height:26,width:26,marginRight:2,color:'#00337b'}}/>
</IconButton>
    </Box>
    <Typography align='center' sx={{fontFamily:'serif'}}>Gaikwad Nagar Aundh, Pune</Typography>
   </Box>

   </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={4} lg={4}>
   <Box sx={{backgroundColor:'#e8eeee',border:2,m:1,"&:hover": {
    borderColor:'#e89323'
  }}}>
   <Box sx={{height:200,backgroundColor:'#e8eeee',margin:2}}>
   <Box sx={{backgroundColor:'#00337b'}}>
   <Typography align='center' sx={{m:2,color:'white',fontFamily:'serif'}}>Pune</Typography>
   </Box>
   
   <Typography align='center' sx={{m:2,color:'#00337b'}}>9850844937</Typography>

    <Box sx={{display:'flex',justifyContent:'center',}}>
    <IconButton color="primary" aria-label="add to shopping cart">
    <AddIcCallIcon sx={{height:26,width:26,marginRight:2,color:'#00337b'}}/>
</IconButton>

    </Box>
 <Typography align='center' sx={{fontFamily:'serif'}}>318, Aurora Tower, East Wing M.g Road Pune</Typography>
   </Box>

   </Box>
  </Grid>
</Grid>








</Box>






      
    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </div>
    )
  }
}

export default Center






