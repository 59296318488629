import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography,Avatar,Button,Container } from '@mui/material';
import Stepper from '../src/Stepper'
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddHomeIcon from '@mui/icons-material/AddHome';
import Groups2Icon from '@mui/icons-material/Groups2';
import Scroller from '../src/Scroller';
import firebase from "../src/firebase"
import download from '../src/download.jpg';
import CircleIcon from '@mui/icons-material/Circle';
import ss from "../src/download.jpg"
import Mobbilestepper from '../src/Mobbilestepper'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import background from "../src/background.png"
import {Divider} from '@mui/material';
import { useNavigate,useLocation } from 'react-router-dom';
import Reactboot from "../src/Reactboot"
import imagebg from "../src/imagebg.jpg"
import two from "../src/assets/twocr.jpg"
import Blog from "../src/Blog"
import manish from "../src/image/manishsahu.png"
import Carrior from './Footer/Carrior';
import Importanthighlight from './Amm/Importanthighlight';
export class Pageone extends Component {

constructor(props) {
  super(props)

  this.state = {
    highlight:[],
    newsandevents: [],
    announcement:[],
  }
}

componentDidMount(){
  var rrrr = firebase.database().ref().child("highlight");
  let highlight = [];
  rrrr.once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
       
        var childData = childSnapshot.val();
        
        highlight.push(childData);

      this.setState({highlight:highlight})
       
        // ...
      });
    });


    var rrrrr = firebase.database().ref().child("announcement");
    let announcement = [];
    rrrrr.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
         
          var childData = childSnapshot.val();
          
          announcement.push(childData);
  
        this.setState({announcement:announcement})
         
          // ...
        });
      });


      var srrrr = firebase.database().ref().child("newsandevents");
  let newsandevents = [];
  srrrr.once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
       
        var childData = childSnapshot.val();
        
        newsandevents.push(childData);

      this.setState({newsandevents:newsandevents})
       
        // ...
      });
    });











}



  render() {
    return (
      <div>
     
      <Grid container >
  <Grid item  xs={12} sm={12} md={12} lg={12}>


<Box>

  <Container  sx={{mb:5}} maxWidth="lg">
  <Grid container sx={{mt:1}} spacing={2}>
  <Grid item xs={12} sm={12} md={8} lg={8}>
   <Box sx={{display:{xs:"none",sm:'block',height:440,width:'100%'}}}>
<Reactboot/>
    </Box>
    <Box sx={{display:{xs:"block",sm:'none'}}}>
    <Mobbilestepper/>
        </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={4} lg={4}>
    <Box sx={{height:440,width:'100%',backgroundColor:'#e1e9e9'}}>
   <Box sx={{display:'flex',flexDirection:'row',overflow:'hidden'}}>
<NotificationsActiveOutlinedIcon sx={{height:25,width:25,color:'red',mt:2,ml:2}}/>
<Typography sx={{color:'red',fontFamily:'sans-serif',mt:2,ml:2,fontWeight:'bold'}}>Important Highlights</Typography>
   </Box>

   <Box sx={{maxHeight:400,overflow:'scroll',scrollbarWidth:"1",mb:2,overflowX:'hidden',  "&::-webkit-scrollbar": {display: "none"}}}>
   

{this.state.highlight.map((rr,i)=>
  <Box>
   <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'center'}} >
   <CircleIcon sx={{height:10,width:10,color:"black",mt:0.5}}/>
   <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:11,sm:11,md:13,lg:13,},width:'100%',fontFamily:'sans-serif'}}>{rr.head}</Typography>
   </Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
  
  </Box>
  )
}

<br/>
   </Box>   


    </Box>
  </Grid>
</Grid>
  </Container>

  </Box>

  </Grid>





  <Grid item xs={12} sm={12} md={12} lg={12}>
  <br/>
  <Container  sx={{mb:2}} maxWidth="lg">

  <Box sx={{maxHeight:300}}>
<Card sx={{maxHeight:200,backgroundColor:'white',borderRadius:5}}>
<Typography color='black' align='center' sx={{fontSize:{xs:19,sm:19,md:22},fontFamily:'serif'}} fontWeight='bold' mt={2}>10+ Years Of Legacy </Typography>

<Grid container spacing={1}>
  <Grid item xs={4} sm={4} md={4} lg={4}>


<Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<SupervisorAccountIcon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>+22</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Specialized faculties</Typography>
</Box>
</Box>
  </Grid>
  <Grid item xs={4} sm={4} md={4} lg={4}>
  <Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<AddHomeIcon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold'}}>+3</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Coaching Centers</Typography>
</Box>
</Box>
  </Grid>
  <Grid item xs={4} sm={4} md={4} lg={4}>
  <Box sx={{display:'flex',justifyContent:'center'}}>
  <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
  <Groups2Icon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
  <Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>+300</Typography>
  <Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Qualified Students </Typography>
  </Box>
  </Box>
  </Grid>
</Grid>
<br/>
</Card>
   </Box>

   </Container>
  </Grid>

<br/>
<br/>
  <Grid item xs={12} sm={12} md={12} lg={12}>
  <Box sx={{backgroundColor:'#00337b',maxHeight:900,marginTop:2}} >
  <Box sx={{mt:1,mb:1}}>
   <Scroller/>
  </Box>
  </Box>
  
  
  </Grid>





<br/>
<br/>
  <Grid item xs={12} sm={12} md={12} lg={12}>

  <Box>
<br/>
<Container maxWidth='lg'>

  <Box sx={{maxHeight:1000,mt:2}}>
  <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
    <Box sx={{maxHeight:400,width:'100%',borderRadius:5,}}>
    <Card sx={{backgroundColor:'white',height:400,width:'100%',overflow:'hidden'}}>
    <Box sx={{backgroundColor:'#00337b',height:50,display:'flex',justifyContent:'center'}}>
    <Box sx={{display:'flex',alignItems:'center'}}>
    <Typography  sx={{fontSize:23,fontFamily:'sans-serif',alignItems:'center',color:'white'}}>Announcements</Typography>
    </Box>
    </Box>
    
<Box sx={{maxHeight:400,overflow:'scroll',mb:2,overflowX:'hidden',overflowY:'hidden' ,'&:hover': {overflowY:'scroll'}}}>

{this.state.announcement.map((rr,i)=>
<Box>
<Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'center'}} >
            <CircleIcon sx={{height:10,width:10,color:"black",mt:0.5}}/>
            <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif'}}>{rr.head}</Typography>
            </Box>
            <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
</Box>

)
}

<br/>
<br/>
</Box>
    </Card>
    </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{maxHeight:400,width:'100%',borderRadius:5}}>
  <Card sx={{backgroundColor:'white',height:400,width:'100%'}}>
  <Box sx={{backgroundColor:'#00337b',height:50,display:'flex',justifyContent:'center'}}>
    <Box sx={{display:'flex',alignItems:'center'}}>
    <Typography  sx={{fontSize:23,fontFamily:'sans-serif',alignItems:'center',color:'white'}}>News & Events</Typography>
    </Box>
    </Box>

    <Box sx={{maxHeight:400,overflow:'scroll',mb:2,overflowX:'hidden',overflowY:'hidden' ,'&:hover': {overflowY:'scroll'}}}>
    
    


    {this.state.newsandevents.map((rr,i)=>
      
      <Box>
      <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'center'}} >
    <CircleIcon sx={{height:10,width:10,color:"black",mt:0.5}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',}}>{rr.head}</Typography>
    </Box>
    <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
    
     </Box>
      
      
      )



    }
    
   
    
    
    
    <br/>
    <br/>
    
    </Box>
  </Card>
  </Box>
  </Grid>
</Grid>
</Box>

</Container>
<br/>
</Box>
  </Grid>







<br/>
<br/>

<Grid item xs={12} sm={12} md={12} lg={12}>
<Box sx={{backgroundColor:'#00337b',mt:2,mb:2}}>
<Container maxWidth="lg">
<Box sx={{display:'flex',justifyContent:'center',}}>
<Box sx={{display:'flex',alignItems:'center',flexDirection:{xs:'column',sm:'column',md:'row'}}}>
<Box sx={{mt:2,mb:2}}>
<Avatar
alt="Remy Sharp"
src={manish}
variant="square"

sx={{ width:{xs:130,sm:130,md:170,lg:170},mt:0.3, height:{xs:140,sm:140,md:180,lg:180},objectFit:'contain',background:'transparent' }}
/>
</Box>

<Box>
<Typography align='justify'  sx={{marginLeft:{xs:1,sm:1,md:7},mb:2,color:'white',fontFamily:'serif',fontSize:{xs:16,sm:16,md:18}}}>There is no substitute for all the hard work that you alone must put in. What we do have is the knowledge, expertise and the right classroom culture. We have a simple definition of our success: When you succeed, we succeed.</Typography>

<Typography sx={{marginLeft:{xs:1,sm:1,md:7},color:'#ffcc01',fontFamily:'sans-serif',fontSize:{xs:16,sm:16,md:18}}}>Manish Sahu </Typography>
<Typography sx={{marginLeft:{xs:1,sm:1,md:7},mb:2,color:'#ffcc01',fontFamily:'serif',fontSize:{xs:16,sm:16,md:17}}}>Director,  Sahu Academy</Typography>
</Box>
</Box>
</Box>


</Container>
</Box>
  </Grid>



















</Grid>
      <Box sx={{display:'flex',justifyContent:'right'}} component='a' href="https://wa.me/919850844937">
      <Fab variant="extended" size="medium"  aria-label="add"  sx={{position:"fixed",bottom:20,right:10,color:'white'}}>
<WhatsAppIcon sx={{ mr: 1,color:'#00337b' }} />
<Typography color='#00337b'>WhatsApp</Typography>
</Fab>
      </Box>

      
      </div>
    )
  }
}



export function Pageonec(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Pageone navigate={navigate}></Pageone>)
}





export default Pageone