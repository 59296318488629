import React, { Component } from 'react'
import Box from '@mui/material/Box';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { Card, Paper, TextField, Typography,Grid } from '@mui/material';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import {IconButton,Link} from '@mui/material';
import moment from 'moment';
import AppBar from '@mui/material/AppBar';
import CircleIcon from '@mui/icons-material/Circle';
import CssBaseline from '@mui/material/CssBaseline';

import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ss from '../src/ss.png'
import Stepper from '../src/Stepper'
import Pageone ,{Pageonec} from './Pageone';
import Footer from './Footer';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import ClearIcon from '@mui/icons-material/Clear';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {Container} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import croppedlogo from "../src/croppedlogo.jpg";
import Aboutus from './Aboutus';
import ReactDOM from "react-dom/client";
import Jeemainresult from './result/Jeemainresult';
import Jeeadvance from './result/Jeeadvance';
import Resultinotherexam from './result/Resultinotherexam';
import Helpfindcouses from './courses/Helpfindcouses';
import Comperhensivetwentyfive from './courses/Comperhensivetwentyfive';
import Comperhensivetwentyfour from './courses/Comperhensivetwentyfour';
import Compact from './courses/Compact';
import Foundationbasic from './courses/Foundationbasic';
import Foundationaccelerated from './courses/Foundationaccelerated';
import Prefoundationbasic from './courses/Prefoundationbasic';
import Comperhensivetwentythree from './courses/Comperhensivetwentythree';
import Foundationadvanced from './courses/Foundationadvanced';
import Foundationadvanceplush from './courses/Foundationadvanceplush';
import Foundationwinttersummer from './courses/Foundationwinttersummer';
import Prefoundationadvanced from './courses/Prefoundationadvanced';
import Summerfoundation from './courses/Summerfoundation';
import Admissioncompactt from './admission/Admissioncompactt';
import Admissioncomperhensivetwenntyfive from './admission/Admissioncomperhensivetwenntyfive';
import Admissioncomperhensivetwentyfour from './admission/Admissioncomperhensivetwentyfour';
import Admissionfoundationaccelerated from './admission/Admissionfoundationaccelerated';
import Admissionfoundationbasic from './admission/Admissionfoundationbasic';
import Admissionprefoundationbasic from './admission/Admissionprefoundationbasic';
import Admisssionsummerfoundation from './admission/Admisssionsummerfoundation';
import Admisssionwinterplushssummer from './admission/Admisssionwinterplushssummer';
import Center from './Center';

import Blog, {Blogc} from './Blog';
import Blogreading from './Blogreading';
import Testing from './Testing';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import Registrationforentranceexam from './Registrationforentranceexam';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Funtest from './Funtest';
import Login from './Login';
import Contact from './Contact';
import Feepayment from './Feepayment';


import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';

import {Divider} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import firebase from "./firebase"

import Slide from '@mui/material/Slide';
import Carrior from './Footer/Carrior';
import Importantsite from './Footer/Importantsite';
import Faculty from './Footer/Faculty';
import Importanthighlight from './Amm/Importanthighlight';
import Announcement from './Amm/Announcement';
import Newsandevents from './Amm/Newsandevents';
import Faq from './Footer/Faq';
import Downloads from './Footer/Downloads';


const router = createBrowserRouter([
  {
    path: "/",
    element:<Pageonec/>,
  },
  {
    path: "/Importanthighlight",
    element:<Importanthighlight/>,
  },
  {
    path: "/Announcement",
    element:<Announcement/>,
  },
  {
    path: "/Newsandevents",
    element:<Newsandevents/>,
  },
  {
    path: "/aboutus",
    element:<Aboutus/>,
  },
  {
    path: "/jeemainresult",
    element:<Jeemainresult/>,
  },
  {
    path: "/jeeadvance",
    element:<Jeeadvance/>,
  },
  {
    path: "/resultinotherexam",
    element:<Resultinotherexam/>,
  },
  {
    path: "/helpfindcouses",
    element:<Helpfindcouses/>,
  },
  {
    path: "/comperhensivetwentyfive",
    element:<Comperhensivetwentyfive/>,
  },
  {
    path: "/comperhensivetwentyfour",
    element:<Comperhensivetwentyfour/>,
  },
  {
    path: "/compact",
    element:<Compact/>,
  },
  {
    path: "/foundationbasic",
    element:<Foundationbasic/>,
  },
  {
    path: "/foundationaccelerated",
    element:<Foundationaccelerated/>,
  },
  {
    path: "/prefoundationbasic",
    element:<Prefoundationbasic/>,
  },
  {
    path: "/comperhensivetwentythree",
    element:<Comperhensivetwentythree/>,
  },
  {
    path: "/foundationadvanced",
    element:<Foundationadvanced/>,
  },
  {
    path: "/foundationadvanceplush",
    element:<Foundationadvanceplush/>,
  },
  {
    path: "/foundationwinttersummer",
    element:<Foundationwinttersummer/>,
  },
  {
    path: "/prefoundationadvanced",
    element:<Prefoundationadvanced/>,
  },
  {
    path: "/summerfoundation",
    element:<Summerfoundation/>,
  },
  {
    path: "/admissioncompactt",
    element:<Admissioncompactt/>,
  },
  {
    path: "/Admissioncomperhensivetwenntyfive",
    element:<Admissioncomperhensivetwenntyfive/>,
  },
  {
    path: "/Admissioncomperhensivetwentyfour",
    element:<Admissioncomperhensivetwentyfour/>,
  },
  {
    path: "/admissionfoundationaccelerated",
    element:<Admissionfoundationaccelerated/>,
  },
  {
    path: "/admissionfoundationbasic",
    element:<Admissionfoundationbasic/>,
  },
  {
    path: "/admissionprefoundationbasic",
    element:<Admissionprefoundationbasic/>,
  },
  {
    path: "/admisssionsummerfoundation",
    element:<Admisssionsummerfoundation/>,
  },
  {
    path: "/admisssionwinterplushssummer",
    element:<Admisssionwinterplushssummer/>,
  },
  {
    path: "/center",
    element:<Center/>,
  },

  {
    path: "/Faq",
    element:<Faq/>,
  },




  {
    path: "/blogc",
    element:<Blogc/>,
  },
  {
    path: "/blogreading",
    element:<Blogreading/>,
  },
  {
    path: "/test",
    element:<Testing/>,
  },
  {
    path: "/funtest",
    element:<Funtest/>,
  },
  {
    path: "/registrationforentranceexam",
    element:<Registrationforentranceexam/>,
  },
  {
    path: "/login",
    element:<Login/>,
  },
  {
    path: "/contact",
    element:<Contact/>,
  },
  {
    path: "/feepayment",
    element:<Feepayment/>,
  },
  {
    path: "/career",
    element:<Carrior/>,
  },

  {
    path: "/importantsite",
    element:<Importantsite/>,
  },
  {
    path: "/faculty",
    element:<Faculty/>,
  },
  {
    path: "/downloads",
    element:<Downloads/>,
  },


]);




const navItems = ['Home', 'About us', 'Contact','Result','Course','Admission','Center'];

export class Main extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      condition:false,
      rr:1,
     paragraph:[],
     para:"",
    paragraphhead:"",
    blog:[],

    posterhead:'',
    posterimg:'',
    backdrop:false,
    posterdata:[],

    ename:'',
    elocation:'',
    enumber:'',


    enquery:false
    }

this.handleChange= this.handleChange.bind();
this.handleChangee=this.handleChangee.bind();

  }


  
  
  handleChange = (e) =>{
    this.setState({
        [e.target.name]:e.target.value,
    })
  }
  handleChangee = (e) =>{
    this.setState({
        [e.target.name]:e.target.files[0],
    })
  }

     
componentDidMount(){
var ref = firebase.database().ref().child("Blogs");
        let blog = [];
        ref.once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
             
              var childData = childSnapshot.val();
              
              blog.push(childData);

            this.setState({blog:blog})
             
              // ...
            });
          });
          

          var ref = firebase.database().ref().child("Posterdata");
          let posterdata = [];
          ref.once('value', (snapshot) => {
              snapshot.forEach((childSnapshot) => {
               
                var childData = childSnapshot.val();
                
                posterdata.push(childData);
  
              this.setState({posterdata:posterdata})
               
                // ...
              });
            });


            try {
              setTimeout(async () => {
               this.setState({enquery:true})
              }, 6000);
            } catch(e) {
              console.log(e);
            }



}






blog=(props)=>{
  this.props.navigate("/funtest")
}

mm=()=>{
  const d = this.state.para;
  console.log(this.state.ss);
  this.setState({
 paragraph:this.state.paragraph.concat([{ss:this.state.ss}])
  });
  
}

save=()=>{
  if(this.state.paragraphhead!=="" && this.state.paragraph.length!==0){
   const key= firebase.database().ref("Blogs").push().key
    firebase.database().ref("Blogs").child(key).set({
    head:this.state.paragraphhead,
      paragraph:this.state.paragraph,
      key:key,
    }).then((d)=>{
    
      this.setState({paragraph:[],paragraphhead:""})
        alert("data successfully placed");
        window.location.reload()
    })

  }else{
    alert("All fields are complesary")
  }
}



  render() {
    return (
      <div>
<Box sx={{backgroundColor:"#2f3f8c"}}>
     <Container maxWidth='lg'>
      <Box sx={{backgroundColor:"#2f3f8c",height:50,width:'100%',position:'sticky',top:0,display:'flex',flexDirection:'row',justifyContent:'space-between',boxShadow:'initial',backgroundAttachment:'fixed'}}>
      <Box sx={{display:"flex",flexDirection:'row',alignItems:'center'}}>
      <AddIcCallIcon sx={{height:26,width:26,marginRight:2,color:'#ffcc01'}}/>
      <Typography color='white'  sx={{fontFamily:'serif',fontSize:20}}>0985-0844-937</Typography>



      </Box>


      <Box sx={{display:{xs:'none',sm:'none',md:'flex',lg:'flex'},flexDirection:'row',alignItems:'center'}}>
      <Typography  component="a" href='/blogc'  color='white' sx={{textTransform:'none',marginLeft:4,fontFamily:'sans-serif',fontSize:16,textDecoration:'none', '&:hover': {color:'white'},}} >Blog</Typography>
      
      <Typography  href='/registrationforentranceexam' component="a" color='white' sx={{marginLeft:4,fontFamily:'sans-serif',fontSize:16 ,textDecoration:'none','&:hover': {color:'white'},}}>Registration For Admission</Typography>
      <Typography component="a" href='/contact' color='white' sx={{marginLeft:4,fontFamily:'sans-serif',fontSize:16,marginRight:4,textDecoration:'none' ,'&:hover': {color:'white'}}}>Contact us</Typography>
      <Button component="a" href='/login' variant="outlined" size='small'  sx={{color:'blue',backgroundColor:'#ffcc01'}}>Login</Button>
      </Box>

<Box component='a' href="/login" sx={{display:{xs:'flex',sm:'flex',md:'none',lg:'none'},flexDirection:'row',backgroundColor:'#033b8a',alignItems:'center',}}>
<PersonIcon  sx={{height:30,width:30,color:'white',margin:'2',color:'#ffcc01'}}/>
</Box>
</Box>
</Container>
</Box>




<Box>

<CssBaseline />
<AppBar  position='sticky' sx={{height:80,top:0,backgroundColor:'#ffffff',display:'flex',justifyContent:'center'}}>
<Container maxWidth='lg'>
  <Toolbar>

  <Box sx={{flexGrow:1}} >
  <img src={croppedlogo} style={{height:70,objectFit:'contain',marginLeft:-13}}/>
  </Box>
         


   {
  this.state.condition?
  <Box sx={{width:'100%',maxHeight:1000,backgroundColor:'#00337b',display:'flex',position:'absolute',top:0,left:0,right:0,overflow:'auto'}}>
 <Box sx={{display:'flex',maxHeight:900,flexDirection:'column',m:2,overflow:'auto'}}>
 <Box onClick={()=>{this.setState({condition:false})}} >
 <CloseIcon sx={{color:'white',height:30,width:30,m:2}}/>
 </Box>
 <Link href="/" sx={{color:'white',fontSize:12,}}>Home</Link>
 <Link href="/blogc" sx={{color:'white',fontSize:12}}>Blog</Link>
 <Link href="/aboutus" sx={{color:'white',fontSize:12}}>About us</Link>
 <Link href="/registrationforentranceexam" sx={{color:'white',fontSize:12}}>Registration for admission</Link>
 <Link href="/contact" sx={{color:'white',fontSize:12}} >Contact us</Link>
 <Link href="/admissioncompactt" sx={{color:'white',fontSize:12}} >Admission</Link>
<br/>

 
<Accordion sx={{backgroundColor:'#00337b'}}>
<AccordionSummary
 expandIcon={<ExpandMoreIcon />}
 aria-controls="panel1a-content"
 id="panel1a-header"

>
 <Typography sx={{color:'white'}}>COURSES</Typography>
</AccordionSummary>
<AccordionDetails sx={{display:'flex',flexDirection:'column'}}>

 <Link href="/comperhensivetwentyfive" sx={{color:'white',fontSize:12}}>Comprehensive 2025(jee)</Link>

<Link href="/comperhensivetwentyfour" sx={{color:'white',fontSize:12}}>Comprehensive 2024(jee)</Link>
 <Link href="/compact" sx={{color:'white',fontSize:12}} >Compact</Link>

 <Link href="/foundationbasic" sx={{color:'white',fontSize:12}}>Foundation basics</Link>
 <Link href="/foundationaccelerated" sx={{color:'white',fontSize:12}}>Foundation Accelerated</Link>
 
 <Link href="/prefoundationbasic" sx={{color:'white',fontSize:12}} >Pre Foundation basics</Link>
 <Link href="/comperhensivetwentythree" sx={{color:'white',fontSize:12}}>Comprehensive 2023</Link>
 <Link href="/foundationadvanced" sx={{color:'white',fontSize:12}}>Foundation Advance</Link>
 <Link href="/foundationadvanceplush" sx={{color:'white',fontSize:12}}>Foundation Advance Plush</Link>
 <Link href="/foundationwinttersummer" sx={{color:'white',fontSize:12}} >Foundation winter+summer</Link>
 <Link href="/prefoundationadvanced" sx={{color:'white',fontSize:12}}>Pre Foundation Advance</Link>
 <Link href="/summerfoundation" sx={{color:'white',fontSize:12}}>Summer Foundation</Link>
</AccordionDetails>
</Accordion>
 
 </Box>
  </Box>:null
} 
   


    <Box sx={{ display: { xs: 'none', sm: 'none',md:'flex',lg:'flex' }}}>
 

    <Button  href='/' component="a" variant="text" sx={{textTransform:'none',fontSize:16,color:'#0d6efd',fontFamily:'sans-serif',m:1}}>Home </Button>
    <Button href='/aboutus' component="a" variant="text" sx={{textTransform:'none',fontSize:16,color:'#0d6efd',fontFamily:'sans-serif',m:1}} >About us</Button>



  
<Dropdown className="d-flex p-2" >
<Dropdown.Toggle variant="text" className="text-primary" id="dropdown-basic">
Course
</Dropdown.Toggle>

<Dropdown.Menu>

<Dropdown.Item href="/comperhensivetwentyfive">Comprehensive 2025(JEE)</Dropdown.Item>
<Dropdown.Item href="/comperhensivetwentyfour">Comprehensive 2024(JEE)</Dropdown.Item>
<Dropdown.Item href="/compact">Compact</Dropdown.Item>
<Dropdown.Item href="/foundationbasic">Foundation Basic</Dropdown.Item>
<Dropdown.Item href="/foundationaccelerated">Foundation Accelerated</Dropdown.Item>
<Dropdown.Item href="/prefoundationbasic">Pre Foundation Basic</Dropdown.Item>
<Dropdown.Item href="/comperhensivetwentythree">Comprehensive 2023(JEE)</Dropdown.Item>
<Dropdown.Item href="/foundationadvanced">Foundation Advanced</Dropdown.Item>
<Dropdown.Item href="/foundationadvanceplush">Foundation Advanced Plus</Dropdown.Item>
<Dropdown.Item href="/foundationwinttersummer">Foundation Winter+Summer</Dropdown.Item>
<Dropdown.Item href="/prefoundationadvanced">Pre Foundation Advanced</Dropdown.Item>
<Dropdown.Item href="/summerfoundation">Summer Foundation </Dropdown.Item>
</Dropdown.Menu>
</Dropdown>





<Button href='/admissioncompactt' component="a" variant="text" sx={{textTransform:'none',fontSize:16,color:'#0d6efd',fontFamily:'sans-serif',m:1}} >Admission</Button>


<Button href='/center' component="a" variant="text" sx={{textTransform:'none',fontSize:16,color:'#0d6efd',fontFamily:'sans-serif',m:1}} >Center</Button>


    </Box>

    <IconButton
    color="inherit"
    aria-label="open drawer"
    onClick={()=>{this.setState({condition:true})}}
    edge="start"
    sx={{  display: { sm: 'block',md:'none' } }}
  >
    <MenuIcon color='primary'/>
  </IconButton>

  </Toolbar>
  </Container>
</AppBar>


<Box component="main" sx={{ pt:0.1 }}>    
<React.StrictMode>

 <RouterProvider router={router} />

   
  </React.StrictMode>


<Footer/>

</Box>
<Box>

<Dialog
open={this.state.enquery}
TransitionComponent={Transition}
keepMounted
onClose={this.handleClose}
aria-describedby="alert-dialog-slide-description"
>
<DialogContent sx={{backgroundColor:'#00337b', width:"100%"}}>

<CloseIcon sx={{color:'white'}} onClick={()=>this.setState({enquery:false})}/>


<Box sx={{backgroundColor:'#00337b',minHeight:{xs:200,sm:200,md:400,lg:400},width:'100%'}}>
<br/>
     
         
  <Box sx={{marginLeft:{xs:2,sm:2,md:7,lg:7},marginRight:{xs:2,sm:2,md:7,lg:7},display:{xs:'block',sm:'block',md:'block',lg:'block'}}}>
  <Box sx={{backgroundColor:'#ffcc01'}}>
  <Typography align='center'sx={{m:1,fontSize:25,color:'blue',fontWeight:600,fontFamily:'sans-serif'}}>Enquiry Form</Typography>
  </Box>
  <br/>
  
  <TextField sx={{backgroundColor:'white'}} onChange={this.handleChange} name='elocation' id="outlined-basic" size='small' fullWidth label="Location" variant="outlined" />
<br/><br/>
<TextField  sx={{backgroundColor:'white'}} onChange={this.handleChange} name='ename' id="outlined-basic" size='small' fullWidth label="Name" variant="outlined" />
<br/><br/>
<TextField  sx={{backgroundColor:'white'}} onChange={this.handleChange} name='enumber' id="outlined-basic" size='small' fullWidth label="Mobile Number" variant="outlined" />

<br/>
  <br/>
  <Box sx={{display:'flex',justifyContent:'center'}}>
  <Button variant="contained" sx={{backgroundColor:'#ffcc01',color:'blue'}} onClick={()=>{
    if(this.state.elocation!=="" || this.state.ename!==""||this.state.enumber!==""){
      const key= firebase.database().ref("enquire").push().key
       firebase.database().ref("enquire").child(key).set({
       location:this.state.elocation,
         name:this.state.ename,
         key:key,
         number:this.state.enumber,
         date:moment().format('L'),
       }).then((d)=>{
       
           alert("data successfully placed");
           window.location.reload()
       })
   
     }else{
       alert("All fields are complesary")
       this.setState({enquery:false})
     }


  }}>
  Enquire now
  </Button>
  
  </Box>

      </Box>





</Box>
</DialogContent>
</Dialog>








</Box>
</Box>







      </div>
    )
  }
}

export default Main






const rows = [
 {
  id:";el;l",
  mm:"w;lpepl"
 },
 {
  id:";el;l",
  mm:"w;lpepl"
 },
 {
  id:";el;l",
  mm:"w;lpepl"
 }




];


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


