import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
   import nn from "../src/nn.webp"
import dd from "../src/dd.jpg"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:'https://www.bakliwaltutorialsiit.com/files/1g0s9scvwrsocuq0wmfd/Popup2.jpg',
  },
  {
    label: 'Bird',
    imgPath:'https://www.bakliwaltutorialsiit.com/files/zpysk0o9xp0tiaa3cag1/CET (2) (1).png',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:'https://www.bakliwaltutorialsiit.com/files/yalvekjngjogmd2tsdlu/EGMO (2) (1).png',
  },
  
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{  flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 0,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
       
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
                <Box
                component="img"
                sx={{
                  height:440,
                  display: 'flex',
                  overflow: 'hidden',
                  width: '100%',
                  objectFit:'cover',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
           
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        
        
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;