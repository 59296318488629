import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
// Comperhensivetwentyfour.js
import two from "../assets/two.jpg"
export class Compact extends Component {
  render() {
    return (
      <div>

      <Box sx={{backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>

      <Box >
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:30,md:30},fontFamily:'serif'}}>Course Details</Typography>
      <br/>
      <Container maxWidth="lg">
      <Box sx={{backgroundColor:'#00337b'}}>
    
<Box sx={{marginLeft:{xs:1,sm:1,md:15,lg:15},marginRight:{xs:1,sm:1,md:15,lg:15}}}>
<Typography sx={{fontWeight:700,fontFamily:'serif',fontSize:24,lineHeight:3,color:'white'}}>Compact </Typography>

<Divider sx={{backgroundColor:'white'}}/>
<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white',}}>Are you ready to conquer the IIT-JEE exam? Achieving success in this highly competitive exam requires more than just a strong understanding of Physics, Chemistry, and Mathematics. It also demands the ability to solve objective-type questions with speed and accuracy.</Typography>


<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white',}}>Our course is designed to give you the edge you need to succeed. We focus on strengthening your core concepts and fundamentals, sharpening your analytical skills, and developing your problem-solving abilities. Intensive problem-solving sessions and tests will help you build speed and accuracy so that you can take on any question with confidence.
</Typography>
<br/>

<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white',}}> Our one-year course is tailored for XII passed students, it will prepare you for all major engineering entrance exams including JEE Mains and Advanced, BITSAT. 
</Typography>
  <br/>
  
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white',}}>Don't let the IIT-JEE exam stand in the way of your engineering dreams. Enroll in our course today and gain the knowledge, skills, and confidence you need to excel in the exam and secure your place in the top engineering colleges.
</Typography>

<br/>

<Typography sx={{fontSize:16,fontFamily:'serif',color:'white',}}>For enquiry, contact us on 98508 44937</Typography>
<br/>

<Divider/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>







</Box>

    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      </div>
    )
  }
}

export default Compact






