import React, { Component } from 'react'
import { Typography,Divider } from '@mui/material'
import { Box, Container } from '@mui/system'
import two from '../assets/two.jpg'
import boy from "../boy.png"
import CircleIcon from '@mui/icons-material/Circle';

export class Jeemainresult extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundImage:`url(${two})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
      <br/>
      
      <Typography align='center' sx={{fontSize:{xs:30,sm:30,md:36,lg:36},fontFamily:'serif'}}>JEE-Mains</Typography>
      <br/>
      <Container maxWidth='lg'>
      <Box sx={{backgroundColor:"#00337b",minHeight:300}}>
      <br/>
      

      <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
      <Box sx={{display:'flex',marginLeft:2}}>
       <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
      </Box>
      <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>Our program boasts an impressive 95% success rate for JEE-MAINS, with many of our students going on to pursue their dream careers in various fields of engineering. Our approach to teaching breaks down even the toughest and trickiest concepts into small, digestible bits for our students, making it easy for them to grasp, learn, and excel.




      </Typography>
      </Box>
      <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
   
      

      <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
      <Box sx={{display:'flex',marginLeft:2}}>
       <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
      </Box>
      <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>For Enquiry, contact us on 98508 44937

      </Typography>
      </Box>
      <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
   
    

      </Box>
      </Container>
      <br/>
      <br/>
      </Box>
      </div>
    )
  }
}

export default Jeemainresult