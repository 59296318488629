import React, { Component } from 'react'
import { Card,Box, Typography, TextField ,Button,Paper, Link} from '@mui/material';
import { Container } from '@mui/system';

import NEETChemicalTransportQuestions from "../Download/NEETChemicalTransportQuestions.pdf"
import NEETChemicalTransportSolutions from "../Download/NEETChemicalTransportSolutions.pdf"
import shmquestion from "../Download/NEET - SHM Questions.pdf"
import shmsolution from "../Download/NEET - SHM Solutions.pdf"



import s from "../Download/NEET - Structure of Atom Questions.pdf"
import e from "../Download/NEET - Structure of Atom Solutions.pdf"
import p from "../Download/NEET - Surface Chemistry Questions.pdf"
import r from "../Download/NEET - Surface Chemistry Solutions.pdf"
import l from "../Download/NEET - Transport in Plants Questions.pdf"
import w from "../Download/NEET - Transport in Plants Solutions.pdf"










export class Importantsite extends Component {
  render() {
    return (
      <div>
      <br/>
      <Container maxWidth="lg">
      <Paper elevation={1}>
      <Box sx={{minHeight:200}}>
      <Box sx={{backgroundColor:"#2f3f8c"}}>
      <br/>
      <Typography align='center' sx={{color:'white',fontSize:19}}>Important Website</Typography>
      <br/>
      </Box>
      

      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,mr:1,fontWeight:600,mt:4}}>IIT Bombay -  <Link href='http://www.iitb.ac.in/' download="NEET - Chemical Transport Questions.pdf">http://www.iitb.ac.in/</Link></Typography>
      </Box>


      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1,fontWeight:600}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Delhi -  <Link href='http://www.iitd.ac.in/'>http://www.iitd.ac.in/</Link></Typography>
      </Box>


      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1,fontWeight:600}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Kanpur -  <Link href='http://www.iitk.ac.in/'>http://www.iitk.ac.in/</Link></Typography>
      </Box>


      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1,fontWeight:600}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}> IIT Kharagpur - <Link href='http://www.iitkgp.ac.in/'>http://www.iitkgp.ac.in/</Link></Typography>
      </Box>




      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Roorkee -  <Link href='http://www.iitr.ac.in/'>http://www.iitr.ac.in/</Link></Typography>
      </Box>

<Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
      <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}> IIT Guwahati -  <Link href='http://www.iitg.ac.in/'>http://www.iitg.ac.in/</Link></Typography>
            </Box>
      

 <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
            <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Madras -  <Link href='https://www.iitm.ac.in/'> https://www.iitm.ac.in/ </Link></Typography>
</Box>
           

<Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
<Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT BHU, Varanasi -  <Link href='http://iitbhu.ac.in/'>http://iitbhu.ac.in/</Link></Typography>
      </Box>




      <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
      <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>ISM Dhanbad -  <Link href='http://www.ismdhanbad.ac.in/'>http://www.ismdhanbad.ac.in/</Link></Typography>
            </Box>


            <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
            <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Hyderabad -  <Link href='http://www.iith.ac.in/'>http://www.iith.ac.in/</Link></Typography>
                  </Box>

                  <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
                  <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Gandhinagar -  <Link href='http://www.iitgn.ac.in/'>http://www.iitgn.ac.in/</Link></Typography>
                        </Box>
      
                        <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
                        <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Bhubneshwar -  <Link href='http://www.iitbbs.ac.in/'>http://www.iitbbs.ac.in/</Link></Typography>
                              </Box>
            
                              <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
                              <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Ropar -  <Link href='http://www.iitrpr.ac.in/'>http://www.iitrpr.ac.in/</Link></Typography>
                                    </Box>
                  
                                    <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
                                    <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Mandi -  <Link href='http://www.iitmandi.ac.in/'>http://www.iitmandi.ac.in/</Link></Typography>
                                          </Box>
                        
                                          <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
                                          <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Patna -  <Link href='http://www.iitp.ac.in/'>http://www.iitp.ac.in/</Link></Typography>
                                                </Box>
                              

                                                <Box sx={{dispaly:'flex',flexDirection:"row",mb:1,mr:1}}>
                                                <Typography sx={{fontSize:15,fontFamily:'serif',ml:4,fontWeight:600}}>IIT Indore -  <Link href='http://www.iiti.ac.in/'>http://www.iiti.ac.in/</Link></Typography>
                                                      </Box>
                                    


<br/>
      
      </Box>
      </Paper>
      </Container>
      <br/>
      </div>
    )
  }
}









export default Importantsite;



