import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
import two from "../assets/two.jpg"
export class Admisssionsummerfoundation extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundImage:`url(${two})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
      <Box sx={{}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:30,sm:30,lg:40,md:40},fontFamily:'sans-serif'}}>Admissions Details</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#fff'}}>
     <Typography align='center' sx={{fontWeight:500,fontFamily:'sans-serif',fontSize:24,lineHeight:3,backgroundColor:'#00337b',color:'white'}}>Summer Foundation
     </Typography>
     <Divider sx={{marginLeft:15,marginRight:15}}/>

     <Box sx={{marginLeft:{xs:1,sm:1,md:15,lg:15},marginRight:{xs:1,sm:1,md:15,lg:15}}}>

     <br/>
     <Typography align='justify' sx={{fontWeight:700,fontSize:14,fontFamily:'sans-serif',color:'#7b7b7b',lineHeight:2}}>For PCB/PCM/JEE/NEET/11th+12th</Typography>
     
     <br/>
     
     <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
        <Box sx={{display:'flex',alignItems:'center'}}>
        <CircleIcon sx={{height:8,width:8,color:"#04566e",display:'flex'}}/>
        </Box>
         <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#04566e',fontWeight:700}}>Advance Payment: 44,000
         </Typography>
         </Box>
     
         <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
         <Box sx={{display:'flex',alignItems:'center'}}>
         <CircleIcon sx={{height:8,width:8,color:"#04566e",display:'flex'}}/>
         </Box>
         
          <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#04566e',fontWeight:700}}> After Bridge Course: 30,000
     
          </Typography>
          </Box>
     
          <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
        <Box sx={{display:'flex',alignItems:'center'}}>
        <CircleIcon sx={{height:8,width:8,color:"#04566e",display:'flex'}}/>
        </Box>
         <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#04566e',fontWeight:700}}>3rd Installment: 70,000
         </Typography>
         </Box>
     
     
         <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
         <Box sx={{display:'flex',alignItems:'center'}}>
         <CircleIcon sx={{height:8,width:8,color:"#04566e",display:'flex'}}/>
         </Box>
          <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#04566e',fontWeight:700}}>4th Installment: 70,000
          </Typography>
          </Box>
       
          <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
        
           <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'sans-serif',color:'#04566e',fontWeight:700}}>Total: 2,14,000
           </Typography>
           </Box>
        <br/>
        <br/>
        
     
        <Typography align='justify' sx={{fontWeight:700,fontSize:14,fontFamily:'sans-serif',color:'#7b7b7b',lineHeight:2}}>For PCMB/IISER/KVPY</Typography>
     
        <br/>
        
        <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
           <Box sx={{display:'flex',alignItems:'center'}}>
           <CircleIcon sx={{height:8,width:8,color:"#04566e",display:'flex'}}/>
           </Box>
            <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#04566e',fontWeight:700}}>Advance Payment: 57,500
     
            </Typography>
            </Box>
        
            <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
            <Box sx={{display:'flex',alignItems:'center'}}>
            <CircleIcon sx={{height:8,width:8,color:"#04566e",display:'flex'}}/>
            </Box>
            
             <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#04566e',fontWeight:700}}> After Bridge Course: 43,500
        
             </Typography>
             </Box>
        
             <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
           <Box sx={{display:'flex',alignItems:'center'}}>
           <CircleIcon sx={{height:8,width:8,color:"#04566e",display:'flex'}}/>
           </Box>
            <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#04566e',fontWeight:700}}>3rd Installment: 83,500
            </Typography>
            </Box>
        
        
            <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
            <Box sx={{display:'flex',alignItems:'center'}}>
            <CircleIcon sx={{height:8,width:8,color:"#04566e",display:'flex'}}/>
            </Box>
             <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:12,sm:12,md:14,lg:14,},width:'100%',fontFamily:'sans-serif',color:'#04566e',fontWeight:700}}>4th Installment: 83,500
             </Typography>
             </Box>
          
             <Box sx={{display:'flex',flexDirection:'row',marginLeft:{xs:1,sm:1,md:4,lg:4},mt:1,justifyContent:'center'}}>
           
              <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'sans-serif',color:'#04566e',fontWeight:700}}>
              Total: 2,68,000
              
              </Typography>
              </Box>
           <br/>
           <br/>
     
     
     
     
     
     
     
     
     
     <br/>
     
     <Typography sx={{fontWeight:700,fontSize:14,fontFamily:'sans-serif',color:'#7b7b7b',lineHeight:4}}>For Enquiry, contact us on 98508 44937</Typography>
     <br/>
     
     <Divider/>
     <br/>
     <Link>Entrance Exam Details And Admissions - click here</Link>
     <br/>
     <br/>
     <Link>Entrance Exam Details And Admissions - click here</Link>
     <br/>
     <br/>
     
     
     
     
     
     
     
     </Box>
     
     
     












    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      </div>
    )
  }
}

export default Admisssionsummerfoundation