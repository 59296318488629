import React from 'react'
import { Box,Container } from '@mui/system';
import {useLocation} from 'react-router-dom';
import { Card, Typography } from '@mui/material';
function Importanthighlight() {
  const location = useLocation();

  return (
    <div>
<Container>
<br/>
<Card>
<Box sx={{backgroundColor:'#00337b'}}>
<Box sx={{m:2}}>
<Typography align='center' sx={{color:'white',fontFamily:'serif',fontWeight:700,fontSize:21}}>{location.state.po.head}</Typography>
</Box>
</Box>
<br/>
<Box sx={{display:'flex',justifyContent: 'center',}}>
<br/>
 <section
    className="not-found-controller"
    dangerouslySetInnerHTML={{ __html:location.state.po.paragraph}}
    />
</Box>
<br/>
<br/>
</Card>
</Container>
    </div>
  )
}

export default Importanthighlight