import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
// Comperhensivetwentyfour.js
import two from "../assets/two.jpg"
export class Foundationadvanced extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
      <Box sx={{}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:30,md:30},fontFamily:'serif'}}>Course Details</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#00337b'}}>
   
<Box sx={{marginLeft:{xs:1,sm:1,md:15,lg:15},marginRight:{xs:1,sm:1,md:15,lg:15}}}>
<Typography sx={{fontWeight:700,fontFamily:'serif',fontSize:24,lineHeight:3,color:'white'}} >Foundation Advanced</Typography>
<Divider sx={{backgroundColor:'white'}}/>
<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>Congratulations on completing the Foundation Basic course at BT! Now, it's time to take your education to the next level with our Foundation Advanced course.
</Typography>

<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>This course is designed to build on the concepts of Physics, Chemistry, and Mathematics that you've learned during the Basic course, taking your understanding to new heights. Not only will you further develop your confidence, but you'll also be well-prepared for your 10th board exams.
</Typography>
<br/>

<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>
Our expertly crafted lectures in Physics, Chemistry, Math, and Logic will ensure that you raise your level in these subjects and build on the momentum that you've gained from the Foundation Basic course. This course is a perfect continuation of the foundation basic course and will give you a competitive edge in the competitive exams.
</Typography>
  <br/>
  <Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>
  Don't miss out on this opportunity to take your education to the next level. Enroll in our Foundation Advanced course today and continue your path to success in competitive exams.
</Typography>
  <br/>


<br/>

<Typography sx={{fontSize:16,fontFamily:'serif',color:'white'}}>For enquiry, contact us on 98508 44937</Typography>
<br/>

<Divider/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>







</Box>

    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      </div>
    )
  }
}

export default Foundationadvanced




