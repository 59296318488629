import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';
// Comperhensivetwentyfour.js
import two from "../assets/two.jpg"
export class Foundationbasic extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>
      <Box sx={{}}>
      <br/>
      <Typography align='center' sx={{fontSize:{xs:20,sm:20,lg:30,md:30},fontFamily:'serif'}}>Course Details</Typography>
      <br/>
      <Container maxWidth="lg" >
      <Box sx={{backgroundColor:'#00337b'}}>
    
<Box sx={{marginLeft:{xs:1,sm:1,md:15,lg:15},marginRight:{xs:1,sm:1,md:15,lg:15}}}>
<Typography sx={{fontWeight:700,fontFamily:'serif',fontSize:24,lineHeight:3,color:'white'}}>Foundation Basic </Typography>
<Divider sx={{backgroundColor:'white'}}/>
<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>Are you ready to lay the foundation for a successful future in competitive exams? Our Foundation course is specifically tailored for 7th and 8th passed students and is designed to give them a head start on their journey.
</Typography>

<br/>
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>Our expertly crafted lectures and corresponding assignment sheets in Physics, Chemistry, and Mathematics are designed to spark an early interest in these subjects, while our logic lectures will help develop your analytical thinking and problem-solving abilities.
</Typography>
<br/>

<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>
This is your chance to get ahead of the competition and start building the knowledge and skills you need to excel in competitive exams. Imagine being able to tackle complex problems with ease and confidence, all while still in middle school.
</Typography>
  <br/>
  
<Typography align='justify' sx={{fontSize:16,fontFamily:'serif',color:'white'}}>Don't miss out on this opportunity to jumpstart your academic journey. Enroll in our Foundation course today and begin your path to success in competitive exams.
</Typography>

<br/>

<Typography sx={{fontSize:16,fontFamily:'serif',color:'white'}}>For enquiry, contact us on 98508 44937</Typography>
<br/>

<Divider/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>
<Link>Entrance Exam Details And Admissions - click here</Link>
<br/>
<br/>







</Box>

    </Box>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      </div>
    )
  }
}

export default Foundationbasic




