import { Typography,Box } from '@mui/material'

import React, { Component } from 'react'

export class Testing extends Component {
  render() {
    return(

<div>
<Box>
<Typography>jakwjerkf mnkfj</Typography>
{
    data.map((post ,index) =>
        <div>
          <h3>{post.heading}</h3>
          <h3>{index}</h3>
          <ul>
            {post.paragraph.map((option,index) => 
             
                index==0?<li>{option.head + index}</li>:null
             
            )}
          </ul>
        </div>
    )





}






</Box>



</div>



     
    )
  }
}

export default Testing


const data = [

{
    id:1,
    heading:"modi",
    paragraph:[
        {
        head:"hello"
       },
       {
        head:"hello,rmtkj "
       },
       {
        head:"hellokekjrt nkjrij"
       },
       {
        head:"hellom kjkjkt"
       },




    ]
},
{
    id:1,
    heading:"modi2",
    paragraph:[
        {
        head:"hello"
       },
       {
        head:"hello,rmtkj "
       },
       {
        head:"hellokekjrt nkjrij"
       },
       {
        head:"hellom kjkjkt"
       },




]
}



]


/*data.map(post =>
            <div>
              <h3>{post.heading}</h3>
              <ul>
                {post.paragraph.map(option => 
                  <li>{option.head}</li>
                )}
              </ul>
            </div>

            */
