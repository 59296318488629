import React, { Component } from 'react'
import { Box } from '@mui/system'

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography,Link, CardMedia, Container } from '@mui/material';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import logosa from '../src/logosa.jpg'
import Lg from "../src/Lg.png"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Faq from '../src/Footer/Faq'
export class Footer extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundColor:'#00337b'}}>
      <Container maxWidth="lg"> 
      <Box sx={{maxHeight:1500,backgroundColor:'#00337b'}}>
      <Box sx={{m:2,}}>
      <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={4} lg={4}>
  <Box sx={{display:'flex',justifyContent:{xs:'left',sm:'left',md:'left',lg:'left'}}}>
  <Box sx={{display:'flex',alignItems:{xs:'left',sm:'left',md:'left',lg:'left'},flexDirection:'column'}}>
<Typography sx={{color:'white',fontSize:25,fontFamily:'serif'}}>Location And Contact</Typography>
<br/>
<Box sx={{display:'flex',flexDirection:'row'}}>
<AddLocationIcon sx={{height:25,width:25,color:'white'}}/>
<Typography align='left' sx={{marginLeft:2,color:'white',fontSize:15,fontFamily:'serif'}} >Flat No:204/03, Amit Shreepal,  Ghole Rd,  Shivajinagar, Pune, Maharastra - 411005</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',mt:1}}>
<CallIcon sx={{height:25,width:25,color:'white'}}/>
<Typography sx={{marginLeft:2,color:'white',fontSize:15,fontFamily:'serif'}} >0985-084-4937</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',mt:1}}>
<EmailIcon sx={{height:25,width:25,color:'white'}}/>
<Typography sx={{marginLeft:2,color:'white',fontSize:15,fontFamily:'serif'}} >manish.g1986@gmail.com</Typography>
</Box>



   </Box>
   </Box>
  </Grid>
  
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{display:'flex',justifyContent:{xs:'left',sm:'left',md:'center',lg:'center'}}}>
  <Box sx={{display:'flex',alignItems:{xs:'left',sm:'left',md:'left',lg:'left'},flexDirection:'column'}}>
  <Typography sx={{color:'white',fontSize:25,fontFamily:'serif'}}>Sitemap</Typography>
 
<br/>
  <Grid container spacing={5}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{display:'flex',flexDirection:'column',marginLeft:{xs:2,sm:2,md:0,lg:0}}}>
 
<Link href="/career" sx={{color:'white',fontSize:15,fontFamily:'serif'}}  underline="none">
 Career
</Link>
<Link href="/importantsite" sx={{color:'white',mt:1,fontSize:15,fontFamily:'serif',}} underline="none">
 Important site
</Link>
<Link href="/faculty" sx={{color:'white',mt:1,fontSize:15,fontFamily:'serif'}} underline="none">
Faculty team
</Link>
<Link href="#" sx={{color:'white',mt:1,fontSize:15,fontFamily:'serif',}} underline="none">
  Important Exam Schedule
</Link>





  </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{display:'flex',flexDirection:'column',marginLeft:{xs:2,sm:2,md:0,lg:0}}}>
  <Link href="/" sx={{color:'white',fontSize:15,fontFamily:'serif'}} underline="none">
  Scholarship 
</Link>

<Link href="/Faq" sx={{color:'white',mt:1,fontSize:15,fontFamily:'serif',}} underline="none">
FAQs
</Link>

<Link href="/downloads" sx={{color:'white',mt:1,fontSize:15,fontFamily:'serif'}} underline="none">
Downloads
</Link>

  </Box>
 
  </Grid>
  



</Grid>







  </Box>
  </Box>
  </Grid>
  
  <Grid item xs={12} sm={12} md={2} lg={2}>
  <Box sx={{display:'flex',justifyContent:{xs:'center',sm:'center',md:'center',lg:'center'}}}>
  <Box sx={{display:'flex',alignItems:{xs:'center',sm:'center',md:'center',lg:'center'},flexDirection:'column'}}>
  <Typography sx={{color:'white',fontSize:25,fontFamily:'serif'}}></Typography>
  <br/>
  <img
  src={Lg}
  style={{height:100,objectFit:'contain',alignItems:'center'}}
  />
  <Box sx={{display:'flex',flexDirection:'row',mt:1,mb:2}}>
  <WhatsAppIcon sx={{height:25,width:25,color:'white'}}/>
  <FacebookIcon sx={{height:25,width:25,color:'white'}}/>
  <InstagramIcon sx={{height:25,width:25,color:'white'}}/>
  
  </Box>
  </Box>
  </Box>
  </Grid>
</Grid>
</Box>

<Box sx={{maxHeight:150,backgroundColor:'#022c70',display:'flex',justifyContent:'center'}}>
<Typography  sx={{fontSize:12,mt:1,mb:1,color:'white',alignItems: 'center',fontFamily:'serif'}}>© 2023 sahu academy,  ALL RIGHTS RESERVED. </Typography>
</Box>

      </Box>
      </Container>
      </Box>
      </div>
    )
  }
}

export default Footer