import Carousel from 'react-bootstrap/Carousel';
import dd from "../src/dd.jpg"
import banner from "./banner.jpg"
import bb from "../src/Banner/bb.jpg"
import cc from "../src/Banner/cc.jpg"
function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100  "
          src={bb}
          alt="First slide"
style={{height:440}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={cc}
          alt="Second slide"
          style={{height:440}}
        />

        
      </Carousel.Item>
      
    </Carousel>
  );
}

export default UncontrolledExample;