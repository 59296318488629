import { Typography,Avatar, Divider } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { Component } from 'react'
import two from "../src/assets/two.jpg"
import ba from "../src/assets/ba.jpg"
import back from "../src/assets/back.jpg"
import Grid from '@mui/material/Grid';
import wife from "../src/image/wife.png"
import manishsahu from '../src/image/manishsahu.png'
import CircleIcon from '@mui/icons-material/Circle';
import manishsahuwife from '../src/image/manishwife.jpeg'

export class Aboutus extends Component {
  render() {
    return (
        <div>
        <Box sx={{backgroundRepeat:'no-repeat',backgroundSize:'cover',position:'relative'}}>


     <Box sx={{}}>
     <Container maxWidth="lg">
     <Box sx={{backgroundColor:'white',minHeight:600}}>
     <Box sx={{backgroundColor:'#00337b',height:50,mt:1,display:'flex',justifyContent:'center'}}>

     <Box sx={{marginLeft:4}}>
      <Typography align='center' sx={{fontSize:21,color:'white',textAlign:'center',mt:1,fontFamily:'sans-serif'}}>About Sahu Academy</Typography>
     </Box>
    
     </Box>
     <br/>

     <Box>
     <Grid container>
  <Grid item xs={12} sm={12} md={5} lg={5}>
 <Box sx={{height:460,backgroundColor:'#ffcc01', display:'flex',justifyContent:'center'}}>
 <br/>
 <Box sx={{display:'flex',alignItems:'center'}}>
<Box>
<Box sx={{borderColor:"#00337b",mt:1}}>
 <Avatar alt="Remy Sharp" src={manishsahu} variant="square" sx={{height:340,width:300,objectFit:'contain'}}/>
 </Box>
 <Typography align='left' sx={{fontSize:19,fontWeight:'bold',color:'#00337b',mt:1}}>Mr. Manish Sahu</Typography>
 <Typography align='left' sx={{fontSize:17,fontWeight:'bold',color:'#00337b',fontWeight:'550'}}>Director</Typography>
 <Typography align='left' sx={{fontSize:17,fontWeight:'bold',color:'#00337b',fontWeight:'550'}}>B.TECH(IIT-R)</Typography>

</Box>
 </Box>
 
<br/>
 </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={7} lg={7}>
   <Box  sx={{height:460,backgroundColor:'#00337b'}}>
 <Box sx={{marginLeft:3,marginRight:3}}>
 <br/>
 <Typography align='left' sx={{fontSize:22,color:'white',mt:1}}>About Director</Typography>
 <Divider sx={{color:'white',backgroundColor:'white'}}/>

 <Box sx={{maxHeight:410,overflow:'scroll',mb:2,overflowX:'hidden', "&::-webkit-scrollbar": {display: "none"}}}>
 <Typography align='justify' sx={{fontSize:{xs:15,sm:15,md:17,lg:17},fontFamily:'serif',color:'white',mt:2,mb:5}}>

As an ex-IITian, Manish sir has the excellent technical skills and problen solving abilities of an engineer. And with more than a decades of being a teacher, Manish sir also has smooth soft skill that make him a great
communicator. His ability to connect with students ensure that learning process  remains  efficient. His management style is a  unique blend of structure
and fluidity. With his passion for teaching and his vision of making a difference in the away 
students learn, Manish sir has continued to learn the trust of many student who went on to succeed
in their goal. And that same passion and vision has been inculcated in the structure of the sahu Academy.
<br/>
The institute is successfully doing so and is now training more than 2300 students at its various centers for various courses.
Apart from his love for studies and students, he also loves music, solving puzzles and playing chess.

 </Typography>
</Box>


 </Box>
</Box>
  </Grid>
</Grid>
     </Box>


<br/>

     <Box>
     <Grid container>
  <Grid item xs={12} sm={12} md={7} lg={7}>
  <Box  sx={{height:450,backgroundColor:'#00337b'}}>
 <Box sx={{marginLeft:3,marginRight:3}}>
 <br/>
 <Typography align='left' sx={{fontSize:22,color:'white',mt:1,mb:1}}>About Founder</Typography>
 <Divider sx={{color:'white',backgroundColor:'white'}}/>

 <Box sx={{maxHeight:410,overflow:'scroll',mb:2,overflowX:'hidden',"&::-webkit-scrollbar": {display: "none"} }}>
 <Typography align='justify' sx={{fontSize:{xs:15,sm:15,md:17,lg:17},fontFamily:'serif',color:'white',mt:2,mb:5}}>
 Ruchi Sahu is the visionary founder of Sahu Academy. With a double degree from JJ College, Mumbai, and a passion for empowering students, she established the academy with the goal of providing high-quality education to learners of all ages. Under the guidance of the dynamic duo of Mr. and Mrs. Sahu, Sahu Academy has grown into a thriving educational institution that is respected for its exceptional programs and dedicated staff. Mrs. Sahu is committed to providing students with the tools and resources they need to succeed, both inside and outside the classroom, and she continues to be an inspiring leader in the education community.
 Maam having  many things like aptitude, talent, abilities (physical and mental) by God to do our work in the education  world.
<br/>
Apart from her love for studies, she also loves music, solving puzzles and playing chess.

 </Typography>
</Box>

 </Box>
   </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={5} lg={5}>
  <Box sx={{height:450,backgroundColor:'#ffcc01', display:'flex',justifyContent:'center'}}>
 <br/>
 <Box sx={{display:'flex',alignItems:'center'}}>
<Box>
<Box  sx={{borderColor:"#00337b",mt:1}}>
 <Avatar alt="Remy Sharp" src={wife} variant="square" sx={{height:340,width:300,objectFit:'contain'}} />
</Box>


 <Typography align='left' sx={{fontSize:19,fontWeight:'bold',color:'#00337b',mt:2}}>Mrs. Ruchi Manish Sahu</Typography>
 <Typography align='left' sx={{fontSize:16,color:'#00337b',fontWeight:'550'}}>Founder</Typography>
</Box>
 </Box>
 
<br/>
 </Box>
  </Grid>
</Grid>
     </Box>


<br/>


<Box sx={{backgroundColor:'#00337b',minHeight:300}}>
<br/>


   <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
   <Box sx={{display:'flex',marginLeft:2}}>
    <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
   </Box>
   <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>Since 2007, we've been a group of dedicated academicians, IITians, and entrepreneurs successfully operating IIT-JEE and NEET entrance exam coaching in Pune. With a team of like-minded staff and faculties from respective fields, we're committed to providing you with the best education possible.
   </Typography>
   </Box>
   <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>

   <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
   <Box sx={{display:'flex',marginLeft:2}}>
    <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
   </Box>
   <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>Our truly integrated course is designed to prepare students for not only XI, XII regular curriculum but also for entrance exams like NEET, IIT-JEE, MHT-CET and more. Our programs are tailored to cater to students right from standards 5 and 6, all the way up to 11th, 12th, and entrance examinations.
   </Typography>
   </Box>
   <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>

  
   <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
   <Box sx={{display:'flex',marginLeft:2}}>
    <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
   </Box>
   <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>We don't stop there, we even provide coaching for exams like SAT and AP, giving you an edge over the competition.
   </Typography>
   </Box>
   <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>

   


   <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',mt:2,marginLeft:2}}>
   <Typography align='justify' sx={{color:'#ffcc01',marginRight:2,marginLeft:1,fontSize:{xs:16,sm:16,md:19,lg:19,},width:'100%',fontFamily:'serif'}}>
   We pride ourselves on our 5 features that make us truly unique :
   </Typography>
   <Divider sx={{backgroundColor:'yellow'}}/>
   </Box>
   <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>



   <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
   <Box sx={{display:'flex',marginLeft:4}}>
    <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
   </Box>
   <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>We believe that small batch sizes are the key to individualized attention and guidance. Our small class sizes allow for personalized mentoring and support, ensuring that each student receives the attention they need to succeed.
   </Typography>
   </Box>
   <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>


  
   <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
   <Box sx={{display:'flex',marginLeft:4}}>
    <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
   </Box>
   <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>Our program focuses on individual guidance and mentoring, ensuring that each student reaches their full potential. Our teachers work one-on-one with students to help them overcome their challenges and achieve their goals.
   </Typography>
   </Box>
   <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>


  
   <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
   <Box sx={{display:'flex',marginLeft:4}}>
    <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
   </Box>
   <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>Our program covers the complete state board class XII board examination, ensuring that students have the knowledge and skills they need to excel in their exams.
   </Typography>
   </Box>
   <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>


   <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
   <Box sx={{display:'flex',marginLeft:4}}>
    <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
   </Box>
   <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>
   We understand that education can be stressful, which is why we've designed our coaching time table in accordance with college time table, to make the process as stress-free as possible.   
   </Typography>
   </Box>
   <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>


   <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
   <Box sx={{display:'flex',marginLeft:4}}>
    <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
   </Box>
   <Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>
   We provide all study material to students, so that they have everything they need to succeed, at their fingertips.
   </Typography>
   </Box>
   <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>

<br/>
<br/>
<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
<Box sx={{display:'flex',marginLeft:2}}>
 <CircleIcon sx={{height:10,width:10,color:"#ffcc01",mt:1}}/>
</Box>
<Typography align='justify' sx={{color:'#ffcc01',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>

Our unique approach to education sets us apart and ensures that our students receive the best possible education experience. Don't miss out on this opportunity to unlock your full potential and achieve success. Enroll in our program today and experience the difference.

</Typography>
</Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>

<br/>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
<Box sx={{display:'flex',alignItems:'center',marginLeft:2}}>
 <CircleIcon sx={{height:10,width:10,color:"#ffcc01",}}/>
</Box>
<Typography align='justify' sx={{color:'#ffcc01',marginRight:2,marginLeft:1,fontSize:{xs:16,sm:16,md:19,lg:19,},width:'100%',fontFamily:'serif'}}>
Results :
</Typography>
</Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
<Box sx={{display:'flex',marginLeft:2}}>
 <CircleIcon sx={{height:10,width:10,color:"#ffcc01",mt:1}}/>
</Box>
<Typography align='justify' sx={{color:'#ffcc01',marginRight:2,marginLeft:1,fontSize:{xs:16,sm:16,md:19,lg:19,},width:'100%',fontFamily:'serif'}}>
JEE Mains:
</Typography>
</Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
<Box sx={{display:'flex',marginLeft:2}}>
 <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
</Box>
<Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>
Our program boasts an impressive 95% success rate for JEE-MAINS, with many of our students going on to pursue their dream careers in various fields of engineering. Our approach to teaching breaks down even the toughest and trickiest concepts into small, digestible bits for our students, making it easy for them to grasp, learn, and excel.

</Typography>
</Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>



<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
<Box sx={{display:'flex',marginLeft:2}}>
 <CircleIcon sx={{height:10,width:10,color:"#ffcc01",mt:1}}/>
</Box>
<Typography align='justify' sx={{color:'#ffcc01',marginRight:2,marginLeft:1,fontSize:{xs:16,sm:16,md:19,lg:19,},width:'100%',fontFamily:'serif'}}>
JEE Advance:
</Typography>
</Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
<Box sx={{display:'flex',marginLeft:2}}>
 <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
</Box>
<Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>
For JEE Advanced, our success rate is an impressive 65%. We understand that JEE Advanced can be challenging, which is why we provide our students with the tools they need to succeed.

</Typography>
</Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
<Box sx={{display:'flex',marginLeft:2}}>
 <CircleIcon sx={{height:10,width:10,color:"#ffcc01",mt:1}}/>
</Box>
<Typography align='justify' sx={{color:'#ffcc01',marginRight:2,marginLeft:1,fontSize:{xs:16,sm:16,md:19,lg:19,},width:'100%',fontFamily:'serif'}}>
Other Exams

</Typography>
</Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',m:1}}>
<Box sx={{display:'flex',justifyContent:'flex-start',marginLeft:2}}>
 <CircleIcon sx={{height:10,width:10,color:"white",mt:1}}/>
</Box>
<Typography align='justify' sx={{color:'white',marginRight:2,marginLeft:1,fontSize:{xs:14,sm:14,md:16,lg:16,},width:'100%',fontFamily:'serif'}}>
For exams such as the MHT-CET, our success rate is a perfect 100%. Our approach to teaching ensures that our students are well-prepared and confident on exam day.


</Typography>
</Box>
<Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>


<br/>

</Box>









     </Box>
     </Container>
     <br/>
     </Box>



     </Box>
      </div>
    )
  }
}

export default Aboutus